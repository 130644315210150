import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AlertType, ButtonPurpose } from '@mhe/ngx-shared';
import { TTSStore } from '../../state/tts.store';
import * as ttsActions from '../../state/tts.actions';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  scan,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { DEFAULT_PLAYBACK_RATE } from '../../state/tts.state';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from 'rxjs';
import { TTSAudioContext } from '@mhe/reader/models';

@Component({
  selector: 'rdrx-audio-controls',
  templateUrl: './audio-controls.component.html',
  styleUrls: ['./audio-controls.component.scss'],
})
export class AudioControlsComponent implements OnDestroy, AfterViewInit {
  @Input() showClose = true;
  @Input() showPlaybackScrubber = true;
  @Input() audioContext: TTSAudioContext;

  @Output() closeEvent = new EventEmitter<void>();

  @ViewChild('controlRow') controlRow: ElementRef;
  @ViewChild('playPauseButton', { read: ElementRef })
    playPauseButton: ElementRef;

  public ButtonPurpose = ButtonPurpose;
  public AlertType = AlertType;
  public playbackRateValues = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
  public playDebounceTime = 500;

  private readonly _debouncedPlay$ = new Subject<{
    playbackPosition?: number
  }>();

  private readonly _destroy$ = new Subject<void>();

  public playbackPosition$ = this.ttsStore.activeAudioPlaybackProgress$.pipe(
    map((p) => p?.playbackPosition ?? 0),
  );

  // External events like scrolling can stop playback.
  // We want that playing state to be reflected here.
  public isPlaying$ = this.ttsStore.activeAudioPlaybackProgress$.pipe(
    map((p) => p?.isPlaying ?? false),
  );

  /**
   * Playback error are errors emitted from the HTML audio mp3.
   */
  public hasPlaybackError$ = this.ttsStore.activeAudioPlaybackProgress$.pipe(
    map((p) => !!p?.error),
    distinctUntilChanged(),
  );

  /**
   * Translation error means the backend failed to provide audio chunks.
   */
  public hasTranslationError$ = this.ttsStore.activeAudio$.pipe(
    map((audioCollection) =>
      audioCollection
        ? audioCollection.chunkCollection?.chunks?.length === 0
        : false,
    ),
    distinctUntilChanged(),
  );

  /**
   * A steam to indicate that something generally went wrong.
   */
  public hasError$ = combineLatest([
    this.hasTranslationError$,
    this.hasPlaybackError$,
  ]).pipe(
    map(
      ([hasPlaybackError, hasTranslationError]) =>
        hasPlaybackError || hasTranslationError,
    ),
  );

  public totalPlaybackTime$ = this.ttsStore.activeAudioTotalPlaybackTime$;

  public volume$ = this.ttsStore.volume$;
  formatVolumeAria = (value: number): number => Math.round(value * 100);

  public isMuted$ = this.ttsStore.volume$.pipe(map((vol) => vol === 0));

  public playbackRate$ = this.ttsStore.playbackRate$;

  ariaValueText$ = combineLatest([this.playbackPosition$, this.totalPlaybackTime$]).pipe(
    map(([playbackPosition, totalPlaybackTime]) => {
      if (totalPlaybackTime) {
        return `${Math.round((playbackPosition / totalPlaybackTime) * 100)}%`;
      }
      return '0%';
    }),
  );

  /**
   * A stream of requests to toggle the speed control.
   * The untouched prop is intended to distinguish between
   * the initial event(s) and the user click events.
   */
  private readonly speedControlDropdownToggle$ = new BehaviorSubject<{
    untouched: boolean
  }>({ untouched: true });

  public showSpeedControlDropDown$ = combineLatest([
    this.speedControlDropdownToggle$,
    this.hasError$,
  ]).pipe(
    withLatestFrom(this.playbackRate$),
    scan((toggleState, [[{ untouched }, hasError], playbackRate]) => {
      if (hasError) {
        // hide the speed control on errors
        return false;
      } else if (untouched) {
        // The user hasn't clicked the button yet, so
        // the intent here is to set the initial state.
        return playbackRate !== DEFAULT_PLAYBACK_RATE;
      } else {
        // The user has clicked the button, so
        // just do what they want.
        return !toggleState;
      }
    }, false),
  );

  private unMutedVolume = 1;

  constructor(private readonly ttsStore: TTSStore) {
    // We need to debounce the play events here in the
    // component because if this audio controls component
    // is destroyed, then the play action delayed from the
    // debounce should not get dispatched.
    this._debouncedPlay$
      .pipe(
        debounceTime(this.playDebounceTime),
        tap(({ playbackPosition }) =>
          this.ttsStore.dispatch(
            ttsActions.play({ playbackPosition, context: this.audioContext }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    // Initial focus on the pause button
    this.playPauseButton?.nativeElement.focus();
  }

  onTogglePlay(): void {
    this.isPlaying$.pipe(first()).subscribe((isPlaying) => {
      if (isPlaying) {
        this.ttsStore.dispatch(ttsActions.pause({}));
      } else {
        this._debouncedPlay$.next({});
      }
    });
  }

  onToggleMute(): void {
    this.volume$.pipe(first()).subscribe((volume) => {
      if (volume > 0) {
        // Store the volume when the user muted so we can restore it
        this.unMutedVolume = volume;
        // Mute the volume
        this.ttsStore.dispatch(ttsActions.updateVolume({ val: 0 }));
      } else {
        // The user clicked mute, so we'll restore that volume
        this.ttsStore.dispatch(
          ttsActions.updateVolume({ val: this.unMutedVolume }),
        );
      }
    });
  }

  onSkip(event): void {
    // Deboucing here is important so we don't trigger
    // an mp3 request for each play dispatch.
    void firstValueFrom(this.totalPlaybackTime$).then(totalPlaybackTime => {
      const nextPlaybackPosition = (totalPlaybackTime ?? 0) / 100 * parseInt(event.value);
      this._debouncedPlay$.next({ playbackPosition: nextPlaybackPosition });
    });
  }

  onPlaybackScrubberMove(): void {
    // The user is sliding, prevent the progress from fighting them.
    // This is going to be called rapidly with a mouse drag, but no performance
    // hit noticed.
    this.ttsStore.dispatch(ttsActions.stopProgressReporting());
  }

  onVolumeChange(event): void {
    this.ttsStore.dispatch(ttsActions.updateVolume({ val: event.value }));
  }

  onPlaybackRateChange(event): void {
    this.ttsStore.dispatch(
      ttsActions.updatePlaybackRate({
        val: event.target.value,
      }),
    );
  }

  onViewPlaybackRateControl(): void {
    this.speedControlDropdownToggle$.next({ untouched: false });
  }

  onClose(): void {
    if (document.querySelector('.tooltip-content')) {
      return;
    }

    this.closeEvent.emit();
  }

  onCloseSpeedControl(event: KeyboardEvent): void {
    if (document.querySelector('.tooltip-content')) {
      return;
    }

    event.stopPropagation();
    this.showSpeedControlDropDown$.pipe(first()).subscribe((isOpen) => {
      if (isOpen) {
        this.speedControlDropdownToggle$.next({ untouched: false });
        this.controlRow?.nativeElement
          ?.querySelector('.speed-control-toggle')
          ?.focus();
      }
    });
  }

  round(decimal: number): number {
    return Math.round(decimal);
  }

  ngOnDestroy(): void {
    // don't allow audio to play without controls
    this.ttsStore.dispatch(ttsActions.pause({}));
    this._destroy$.next();
    this._destroy$.complete();
  }
}
