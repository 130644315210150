import { Injectable } from '@angular/core';

import {
  LexileLevel,
  Manifest,
  MediaOverlay,
  Package,
  PackageMetadata,
  ParserOptions,
  Spine,
  SpineItem,
  TocItem,
  isLexileLevel,
} from '@mhe/reader/models';
import { ParsedContainer } from './epub-loader.model';
import { cfiFromSpineItem } from '@mhe/reader/utils';

@Injectable({ providedIn: 'root' })
export class EPubParser {
  private static readonly nonSelectorList = ['//', '@', 'dc:'];

  // Takes in URL and parses URI
  parseUri(url: string): {
    origin: string
    href: string
    protocol: string
    hostname: string
    port: string
    pathname: string
    search: string
    hash: string
    host: string
    extension: string | undefined
    base: string
  } {
    const parsedUrl = new URL(url);

    // Calculate Base (Split)
    const base = parsedUrl.href.split('/');
    // Pop one off the end
    base.pop();

    // Return URI Object
    return {
      origin: parsedUrl.protocol + '//' + parsedUrl.host,
      href: parsedUrl.href,
      protocol: parsedUrl.protocol,
      hostname: parsedUrl.hostname,
      port: parsedUrl.port,
      pathname: parsedUrl.pathname,
      search: parsedUrl.search,
      hash: parsedUrl.hash,
      host: parsedUrl.host,
      extension: parsedUrl.pathname.split('.').pop(),
      base: base.join('/') + '/',
    };
  }

  // Takes in Container Node and returns object
  parseContainer(
    containerNode: Document,
    params: ParserOptions,
  ): ParsedContainer {
    // Get Root Nodes or single Root Node
    const rootNodes = this.epubQuerySelectorAll(
      containerNode,
      '//rootfile',
    ) as NodeListOf<Element>;
    const rootNode = this.selectRootfile(rootNodes, params);
    // rootNode Doesn't Exist
    if (!rootNode) {
      if (params['mhe:selectionTag']) {
        throw new Error(
          'Root File for ' + params['mhe:selectionTag'] + ' not found',
        );
      } else {
        throw new Error('Root File Not Found');
      }
    }

    const containerObj: ParsedContainer = {
      selectionTags: this.getAllMheTags(rootNodes),
      fullPath: rootNode.getAttribute('full-path'),
      encoding: containerNode.characterSet,
      basePath: '',
      mediaType: rootNode.getAttribute('media-type'),
    };

    // Get Base Path
    // Make sure fullPath has a slash
    if (containerObj.fullPath?.includes('/')) {
      const paths = containerObj.fullPath.split('/');
      paths.pop();
      containerObj.basePath = paths.join('/') + '/';
    }

    return containerObj;
  }

  // Select rootfile
  selectRootfile(
    rootfiles: NodeListOf<Element>,
    params: ParserOptions,
  ): null | Element {
    if (rootfiles.length === 1) {
      return rootfiles[0];
    }
    if (rootfiles.length < 1) {
      return null;
    }
    let defaultRootFile: Element | undefined;
    let onLevelRootFile: Element | undefined;
    const defaultLevelLabel = params?.defaultLevel ?? 'on_level';
    const onLevelLabel = 'on_level';
    for (let i = rootfiles.length - 1; i >= 0; i--) {
      const mheAttrs: Attr[] = [].filter.call(
        rootfiles[i].attributes,
        function(attr) {
          return /^mhe:selectionTag/.test(attr.name);
        },
      );
      if (mheAttrs && mheAttrs.length > 0) {
        for (let j = mheAttrs.length - 1; j >= 0; j--) {
          if (mheAttrs[j].nodeValue === defaultLevelLabel) {
            defaultRootFile = rootfiles[i];
          }
          if (mheAttrs[j].nodeValue === onLevelLabel) {
            onLevelRootFile = rootfiles[i];
          }

          for (const key in params) {
            if (
              key === mheAttrs[j].name &&
              mheAttrs[j].nodeValue === params[key]
            ) {
              return rootfiles[i];
            }
          }
        }
      }
    }

    if (defaultRootFile ?? (!params && defaultRootFile)) {
      return defaultRootFile;
    } else if (onLevelRootFile) {
      console.log(
        'Leveled content for ' +
          defaultLevelLabel +
          ' not found loading the on_level content instead.',
      );
      return onLevelRootFile;
    }
    return null;
  }

  getAllMheTags(rootNodes: NodeListOf<Element>): Array<Record<string, string>> {
    const selectionTags: any[] = [];
    for (let i = 0, len = rootNodes.length; i < len; i++) {
      const mheSelectionTag = rootNodes[i].getAttribute('mhe:selectionTag');
      const renditionLabel = rootNodes[i].getAttribute('rendition:label');
      if (mheSelectionTag && renditionLabel) {
        const temp = {};
        temp[mheSelectionTag] = renditionLabel;
        selectionTags.push(temp);
      }
    }
    return selectionTags;
  }

  // Takes in PackageNode and returns object
  parsePackage(packageNode: Document, baseUrl: string): Package {
    const metadataNode = this.epubQuerySelector(packageNode, '//metadata');
    if (!metadataNode) {
      throw new Error('No Metadata Found');
    }

    const manifestNode = this.epubQuerySelector(packageNode, '//manifest');
    if (!manifestNode) {
      throw new Error('No Manifest Found');
    }

    const spineNode = this.epubQuerySelector(packageNode, '//spine');
    if (!spineNode) {
      throw new Error('No Spine Found');
    }

    const uniqueId = this.getUniqueIdentifier(packageNode);

    const manifest = this.parseManifest(manifestNode);

    const tocPath = this.parseTocPath(manifestNode);

    const ncxPath = this.parseNcxPath(manifestNode);

    // Parse CoverPath
    const coverPath = this.parseCoverPath(manifestNode);

    // Parse Metadata
    const metadata = this.parseMetadata(metadataNode, uniqueId);

    const spineChildNodes = spineNode.parentNode?.children;

    // Get Spine Node Index
    const spineNodeIndex = Array.prototype.indexOf.call(
      spineChildNodes,
      spineNode,
    );

    // Parse Spine
    const spine = this.parseSpine(spineNode, manifest, baseUrl);

    return {
      metadata,
      spine,
      manifest,
      tocPath,
      ncxPath,
      coverPath,
      spineNodeIndex,
      baseUrl,
    };
  }

  // Parse Package Node for Unique Identifier
  getUniqueIdentifier(packageNode: Document): string {
    const firstNode = this.epubQuerySelector(
      packageNode,
      '//package',
    ) as Element;
    return firstNode.getAttribute('unique-identifier') as string;
  }

  // Parse Manifest Node
  parseManifest(manifestNode: Element): Manifest {
    // Define Manifest Object
    const manifest = {};

    this.epubQuerySelectorAll(manifestNode, '//item')?.forEach((n) => {
      const id = n.getAttribute('id');
      manifest[id as string] = {
        id,
        href: n.getAttribute('href') ?? '',
        type: n.getAttribute('media-type') ?? '',
        properties: n.getAttribute('properties') ?? '',
        mediaOverlay: n.getAttribute('media-overlay') ?? '',
      };
    });

    return manifest;
  }

  parseTocPath(manifestNode: Element): string | false {
    // Define NavNode & Query for Node
    const navNode = this.epubQuerySelector(
      manifestNode,
      '//item[@properties*="nav"]',
    );
    return navNode ? (navNode.getAttribute('href') as string) : false;
  }

  parseNcxPath(manifestNode: Element): string | false {
    const ncxNode = this.epubQuerySelector(
      manifestNode,
      '//item[@media-type="application/x-dtbncx+xml"]',
    );
    return ncxNode ? (ncxNode.getAttribute('href') as string) : false;
  }

  parseCoverPath(manifestNode): string | false {
    const coverNode = this.epubQuerySelector(
      manifestNode,
      '//item[@properties="cover-image"]',
    );
    return coverNode ? (coverNode.getAttribute('href') as string) : false;
  }

  parseSpine(
    spineNode: Element,
    manifest: Manifest,
    baseUrl: string,
  ): SpineItem[] {
    // Array is built up in this method
    const spine: SpineItem[] = [];

    this.epubQuerySelectorAll(spineNode, '//itemref')?.forEach((n, i) => {
      const rawProps = n.getAttribute('properties') ?? '';
      const properties = rawProps.length ? rawProps.split(' ') : [];
      // Object built up through mutation in this method
      const spineItem: Partial<SpineItem> = {
        index: i,
        idref: n.getAttribute('idref') ?? '',
        id: n.getAttribute('id') ?? '',
        linear: n.getAttribute('linear') ?? 'yes',
        properties,
      };
      const manifestItem = manifest[spineItem.idref as string];
      if (manifestItem) {
        spineItem.href = manifestItem.href;
        spineItem.url = baseUrl + spineItem.href;
      }
      spine.push(spineItem as SpineItem);
    });

    return spine;
  }

  parseTocNCXContents(tocNode: Document, spineObj: Spine): TocItem[] {
    // Get top level navMap
    const navMap = this.epubQuerySelector(tocNode, '//navMap');

    if (!navMap) {
      return [];
    }

    return this.getTocFromNCX(navMap, spineObj);
  }

  parseTocXHTMLContents(tocNode: Document, spineObj: Spine): TocItem[] {
    // Find top order list
    const olMap = this.epubQuerySelector(tocNode, '//ol');

    if (!olMap) {
      return [];
    }

    return this.getTocFromXHTML(olMap, spineObj);
  }

  parsePages(tocNode: Document): Record<string, string> | null {
    const pages = {};
    try {
      const pageNav = tocNode.querySelector('[*|type="page-list"]');
      if (!pageNav) {
        return null;
      }
      const pageList = this.epubQuerySelectorAll(pageNav, '//a');

      if (!pageList || pageList.length < 1) {
        return null;
      }

      for (let i = 0, len = pageList.length; i < len; i++) {
        const pageNum = pageList[i].innerHTML;
        const pageLink = pageList[i].getAttribute('href');
        if (pageNum && pageLink) {
          pages[pageNum] = pageLink;
        }
      }

      return pages;
    } catch (e) {
      return null;
    }
  }

  parseMetadata(metadataNode: Element, uniqueId: string): PackageMetadata {
    const mheMetadata = {};
    this.epubQuerySelectorAll(
      metadataNode,
      '//meta[property^="mhe:"]',
    )?.forEach((n) => {
      const prop = n.getAttribute('property') as string;
      mheMetadata[prop] = n.textContent;
    });

    let identifier = this.getElementText(
      metadataNode,
      `//dc:identifier[@id='${uniqueId}']`,
    );
    // Catch for not-well-formed ePubs
    if (!identifier) {
      identifier = this.getElementText(metadataNode, '//dc:identifier') || '';
    }

    // Define Metadata Object
    return {
      title: this.getElementText(metadataNode, '//dc:title'),
      creator: this.getElementText(metadataNode, '//dc:creator'),
      description: this.getElementText(metadataNode, '//description'),
      createdDate: this.getElementText(metadataNode, '//date'),
      modifiedDate: this.getElementText(
        metadataNode,
        '//meta[@property="dcterms:modified"]',
      ),
      publisher: this.getElementText(metadataNode, '//dc:publisher'),
      language: this.getElementText(metadataNode, '//dc:language'),
      rights: this.getElementText(metadataNode, '//dc:rights'),
      layout: this.getElementText(
        metadataNode,
        '//meta[@property="rendition:layout"]',
      ),
      orientation: this.getElementText(
        metadataNode,
        '//meta[@property="rendition:orientation"]',
      ),
      spread: this.getElementText(
        metadataNode,
        '//meta[@property="rendition:spread"]',
      ),
      mediaOverlay: this.getMetaMediaOverlay(metadataNode),
      mheMetadata,
      identifier,
    };
  }

  getElementText(node: Element, tag: string): string {
    const tagNode = this.epubQuerySelector(node, tag);
    if (tagNode && typeof tagNode === 'object') {
      return tagNode.textContent ?? '';
    }
    return '';
  }

  getMetaMediaOverlay(metadataNode: Element): MediaOverlay {
    const mediaItems: any[] = [];
    let duration = '';
    this.epubQuerySelectorAll(
      metadataNode,
      '//meta[@property="media:duration"]',
    )?.forEach((n) => {
      if (n.hasAttribute('refines')) {
        mediaItems.push({
          refines: n.getAttribute('refines') as string,
          duration: n.textContent as string,
        });
      } else {
        duration = n.textContent as string;
      }
    });

    return {
      narrator: this.getElementText(
        metadataNode,
        '//meta[@property="media:narrator"]',
      ),
      activeClass: this.getElementText(
        metadataNode,
        '//meta[@property="media:active-class"]',
      ),
      playbackActiveClass: this.getElementText(
        metadataNode,
        '//meta[@property="media:playback-active-class"]',
      ),
      mediaItems,
      duration,
    };
  }

  getTocFromNCX(parentNode: Element, spineObj: Spine): TocItem[] {
    const toc = [];

    if (!parentNode) {
      return toc;
    }

    const navPoints: Element[] = [];
    parentNode.childNodes.forEach((n) => {
      if (
        n.nodeType === Node.ELEMENT_NODE &&
        n.nodeName.toLowerCase() === 'navpoint'
      ) {
        navPoints.push(n as Element);
      }
    });

    if (navPoints.length === 0) {
      return [];
    }

    return navPoints.map((navItem) => {
      let navId = navItem.getAttribute('id') ?? null;
      const contentNode = this.epubQuerySelector(
        navItem,
        '//content',
      ) as Element;
      const contentSrc = contentNode.getAttribute('src');
      const navLabel = this.epubQuerySelector(navItem, '//navLabel') as Element;
      const navText = navLabel.textContent ? navLabel.textContent.trim() : '';
      const baseUrl = contentSrc?.split('#')[0];
      const spinePos = this.getSpinePos(spineObj, baseUrl as string);
      const spineItem = spineObj.spineItems[spinePos];

      if (!navId) {
        if (spinePos) {
          navId = spineItem.id;
        }
      }

      const parentId =
        parentNode && (parentNode.getAttribute('id') as string)
          ? parentNode.getAttribute('id')
          : null;

      return {
        id: navId,
        href: contentSrc as string,
        label: navText,
        spinePos,
        spineItem,
        parentId,
        subItems: this.getTocFromNCX(navItem, spineObj),
        cfi: spineItem ? cfiFromSpineItem(spineItem) : null,
      } satisfies TocItem;
    });
  }

  getTocFromXHTML(parentNode: Element, spineObj: Spine): TocItem[] {
    const toc = [];

    if (!parentNode) {
      return toc;
    }

    const navPoints: Element[] = [];
    parentNode.childNodes.forEach((n) => {
      if (
        n.nodeType === Node.ELEMENT_NODE &&
        n.nodeName.toLocaleLowerCase() === 'li'
      ) {
        // Not sure how to type this array because I'm not sure what checking the node name really means
        navPoints.push(n as Element);
      }
    });

    if (navPoints.length === 0) {
      return [];
    }

    return navPoints.map((navItem) => {
      const contentNode = this.epubQuerySelector(navItem, '//a') as Element;
      const contentSrc = contentNode.getAttribute('href');
      let navId = contentSrc?.split('#')[1] ?? null;
      const navLabel = contentNode.textContent
        ? contentNode.textContent.trim()
        : '';
      const baseUrl = contentSrc?.split('#')[0];
      const spinePos = this.getSpinePos(spineObj, baseUrl as string);
      const spineItem = spineObj.spineItems[spinePos];

      if (!navId) {
        if (spinePos) {
          navId = spineItem.id;
        }
      }

      const parentId =
        parentNode && (parentNode.getAttribute('id') as string)
          ? parentNode.getAttribute('id')
          : null;

      const nodeName = navItem.getAttribute('data-node-name');

      return {
        id: navId,
        href: contentSrc as string,
        label: navLabel,
        nodeName,
        spinePos,
        spineItem,
        parentId,
        subItems: this.getTocFromXHTML(
          this.epubQuerySelector(navItem, '//ol') as Element,
          spineObj,
        ),
        cfi: spineItem ? cfiFromSpineItem(spineItem) : null,
      } satisfies TocItem;
    });
  }

  getSpinePos(spineObj: Spine, baseUrl: string): number {
    let position = spineObj.spineItemsByHref[baseUrl];
    if (position !== undefined) {
      return position;
    }
    Object.keys(spineObj.spineItemsByHref).forEach((k) => {
      const checkKeyMatch = baseUrl.endsWith(k);
      if (k.includes(baseUrl) || checkKeyMatch) {
        position = spineObj.spineItemsByHref[k];
      }
    });
    return position;
  }

  epubQuerySelector(node: Element | Document, query: string): Element | null {
    if (!node || !query) {
      return null;
    }

    EPubParser.nonSelectorList.forEach((item) => {
      query = query.replace(item, '');
    });
    return node.querySelector(query);
  }

  epubQuerySelectorAll(
    node: Element | Document,
    query: string,
  ): NodeListOf<Element> | null {
    if (!node || !query) {
      return null;
    }

    EPubParser.nonSelectorList.forEach((item) => {
      query = query.replace(item, '');
    });
    return node.querySelectorAll(query);
  }

  /** lexile levels */
  parseLexileLevels(lexileXml: XMLDocument): LexileLevel[] {
    let levels: LexileLevel[] = [];
    const levelNodes = lexileXml.querySelectorAll('level');

    levelNodes.forEach((node) => {
      const level = (node.attributes as any).range?.value;
      if (level && isLexileLevel(level)) {
        levels = [...levels, level];
      }
    });

    return levels;
  }
}
