/* eslint-disable max-len */
export const esES = {
  iframe: {
    main_content: 'Contenido principal',
    left_content: 'Contenido de la Página Izquierda',
    right_content: 'Contenido de la Página Correcta',
    portrait_mode: 'Cambiar al modo retrato',
    album_mode_off: 'Cambiar a vista de página única',
    album_mode_on: 'Volver a la vista de doble página',
    zoom_in_page: 'Zoom en la página',
    zoom_out_page: 'Alejar la página',
    move_page_up: 'Mover hacia arriba',
    move_page_down: 'Mover hacia abajo',
    move_page_left: 'Moverse a la derecha',
    move_page_right: 'Mover hacia la izquierda',
    image_moved: {
      down: 'Imagen movida hacia abajo',
      left: 'Imagen movida a la izquierda',
      right: 'Imagen movida a la derecha',
      up: 'Imagen movida hacia arriba',
    },
    reading_content: 'Contenido de lectura',
  },
  alert: {
    invalid_releaseId_banner: 'Estamos teniendo problemas para cargar el contenido. Por favor, inténtalo de nuevo. Si el problema persiste, por favor contacta con',
    invalid_releaseId_banner_link: 'Soporte Técnico.',
  },
  annotation: {
    begin: 'Comenzar',
    end: 'Fin',
    new: 'Nueva',
    selected: 'Seleccionada',
    created: 'creada',
    deleted: 'eliminada',
    annotation: 'Anotación',
    note: 'Nota',
    placemark: 'Marcador',
    'epr-shape-square-filled': 'Destaque',
    'epr-shape-square-outline': 'Recuadro',
    'epr-shape-round-outline': 'Circulo',
    'epr-shape-underline': 'Rayar',
    keyboard_activate_message:
      'Oprima la tecla clave para abrir el diálogo de anotación. Abre en un modal.',
    keyboard_lr_message:
      'Presione ENTER para acceder a recursos de aprendizaje adicionales para este concepto. Abre en un modal.',
    on_first_message:
      'Para un acceso rápido, todas sus anotaciones se pueden encontrar en el menú de la barra lateral.',
    batch_delete: {
      title: 'Eliminar elementos destacados, notas y marcas de posición',
      message_all:
        '¿Está seguro de que desea eliminar todos sus elementos destacados, notas y marcas de posición en <b>todas las páginas</b>? Esto no se puede deshacer.',
      message_pg:
        '¿Está seguro de que desea eliminar todos sus elementos destacados, notas y marcas de posición en esta página? Esto no se puede deshacer.',
      delete_error:
        'Se produjo un error al eliminar tus anotaciones. Reinicia tu libro e inténtalo de nuevo.',
      success_message: {
        highlights: 'destacado eliminado con éxito.',
        note: 'nota eliminada con éxito.',
        placemark: 'marca de posición eliminado con éxito.',
      },
    },
    loading_highlights: 'Cargando destacados...',
    quick_annotation_message: 'Estás en el modo de anotación rápida. Selecciona X para para salir.',
    annotation_toolbar: 'barra de herramientas de anotación',
  },
  context: {
    context_menu: 'Menú del apunte',
    enabled: 'Habilitado',
    add: 'Añadir',
    set_color: 'Establecer color {{color}}',
    highlight: {
      yellow: 'amarillo',
      blue: 'azul',
      green: 'verde',
      pink: 'rosa',
    },
    quick_annotation: 'Anotación Rápida',
    quick_annotation_info: 'Con el modo de anotación rápida, puede resaltar, subrayar, hacer anotaciones en forma de recuadro o circulares en varias selecciones de texto.',
    add_highlight: 'Añadir resaltado de anotación',
    add_box: 'Añadir Recuadro de anotación',
    add_circle: 'Añadir Círculo de anotación',
    add_underline: 'Añadir Subrayado de anotación',
    add_note: 'Añadir La Nota',
    edit_note: 'Editar nota',
    save_note: 'Guardar',
    add_note_textarea: 'Añadir La Nota área de texto',
    add_note_charcter_out_of: 'de',
    add_note_charcters_remaining: 'caracteres utilizados',
    add_placemark: 'Añadir Marcador',
    lookup_dictionary: 'Buscar en el Diccionario',
    note_prompt: 'Añadir La Nota',
    read_selected: 'Leer Seleccionado',
    detached_highlight: 'Este resaltado ya no enlaza con el texto debido a cambios de contenido.',
    detached_note: 'Esta nota ya no enlaza con el texto debido a cambios de contenido.',
    detached_placemark: 'Esta marca de posición ya no vincula al texto debido a cambios de contenido.',
    remove_highlight: 'Eliminar Más Destacado de anotación',
    remove_box: 'Eliminar Recuadro de anotación',
    remove_circle: 'Eliminar Círculo de anotación',
    remove_underline: 'Eliminar Subrayado de anotación',
    remove_note: 'Eliminar La Nota',
    remove_placemark: 'Eliminar Marcador',
    remove_annotation: 'Anotación eliminada con éxito.',
    remove_annotation_highlight: 'Destacado eliminado con éxito.',
    remove_annotation_placemark: 'Marca de posición eliminada con éxito.',
    remove_annotation_note: 'Nota eliminada con éxito.',
    move: 'Moverse',
    previous: 'Anterior',
    next: 'Próximo',
    enabled_highlight: 'Destacado de anotación habilitado',
    enabled_circle: 'Círculo de anotación habilitado',
    enabled_box: 'Recuadro de anotación habilitado',
    enabled_underline: 'Subrayado de anotación habilitado',
    enabled_yellow: 'Amarillo habilitado',
    enabled_green: 'Verde habilitado',
    enabled_blue: 'Azul habilitado',
    enabled_pink: 'Rosa habilitado',
    drag_activated: 'Use las teclas de flecha o el mouse para mover el menú contextual',
    drag_deactivated: 'Alternar para activar la función de arrastrar y soltar',
  },
  navigation: {
    navigation: 'Navigation',
    toolbar: 'Barra de herramientas',
    previous: 'Anterior',
    next: 'Siguiente',
    navigation_interval: '{{lower}} de {{upper}}',
    no_title: 'Sin Título',
    loaded: 'cargada',
    selected_section: 'Su sección seleccionada cargada',
    reading_navigation: 'Recitación',
    device_mode: 'Cambiar al modo de vista previa del dispositivo',
    back_button: 'Ir a la ubicación anterior',
    highlight_button: 'Anotar selección',
    toolkit: 'Kit de herramientas',
    close: 'Cerrar',
    assignment_pages: 'Páginas Asignadas',
    assignment_page: 'Página Asignada',
  },
  reset_activities: {
    cancel: 'Cancelar',
    delete: 'Borrar datos',
    title: 'Reiniciar actividades',
    message_all:
      '¿Está seguro de que desea restablecer todas las actividades en todas las páginas? Esto borrará todas las respuestas.',
    message_exhibit:
      '¿Está seguro de que desea restablecer todas las actividades de esta página? Esto borrará todas las respuestas.',
    delete_error:
      'Hubo un error al restablecer sus actividades. Reinicia tu libro e inténtalo de nuevo.',
  },
  overflow: {
    more: 'Más',
    author_options: 'Opciones De Vista Instructor',
    teacher_options: 'Opciones de profesores',
    student_options: 'Opciones para estudiantes',
    teacher_expanded_announce: 'Opciones de profesores expandido',
    teacher_collapsed_announce: 'Opciones para profesores colapsado',
    clear_highlights: 'Eliminar Destacados​',
    clear_widget_data_exhibit: 'Reiniciar actividades en esta página',
    clear_widget_data: 'Restablecer todas las actividades en este recurso',
    course_style: 'Estilo De Curso',
    course_style_prompt: 'Buscar Estilos',
    quick_annotation: 'Anotación Rápida',
    save_heat_map: 'Guardar Mapa De Calor',
    show_heat_map: 'Mostrar Mapa De Calor​',
    style_not_found: 'Ningún resultado encontrado para "{{query}}".',
    teacher_content: 'Notas Del Maestro',
    highlights: {
      clear_all: 'Borrar todos los aspectos destacados de este recurso',
      clear_all_warning:
        '¿Está seguro de que desea eliminar todos los aspectos destacados de todas las páginas?',
      clear_pg: 'Destacados claros en esta página',
      clear_pg_warning:
        '¿Está seguro de que desea eliminar todos los aspectos destacados de esta página?',
      warning_title: 'Quitar destacados',
    },
  },
  shared: {
    close: 'Cerrar',
    ok: 'OK',
    cancel: 'Cancelar',
    submit: 'Enviar',
    delete: 'Borrar',
    with: 'con',
    and: 'y',
    accept: 'aceptar',
    generic_save_error: 'No pudimos guardar tu trabajo. Inténtalo de nuevo.',
  },
  scrubber: {
    chapter_navigation: 'navegación del capítulo',
    chapter_slider: 'Deslizador de capítulo',
    back: 'La espalda',
  },
  link: {
    open_external: 'Se abre en una nueva ventana',
    open_modal: 'Se abre en un modal',
  },
  dialog: {
    accept: 'Aceptar',
    are_you_sure: {
      highlight: '¿Estás seguro de que deseas eliminar tu destacado?',
      highlight_batch: '¿Estás segura de que quieres eliminar las anotaciones seleccionadas?',
      highlight_title: 'Eliminar lo destacado',
      highlight_batch_title: 'Borrar Resaltados',
      note_batch_title: 'Borrar Notas',
      placemark_batch_title: 'Borrar Marcadores',
      note: '¿Seguro que quieres borrar tu nota?',
      notes_batch: '¿Estás segura de que quieres eliminar permanentemente estas notas?',
      note_title: 'Borrar Nota',
      placemark: '¿Seguro que quieres eliminar tu marcador?',
      placemarks_batch: '¿Estás seguro de que deseas eliminar permanentemente estos marcadores?',
      placemark_title: 'Eiminar marcador',
      dismiss_print_file: '¿Está seguro de que quieres descartar este archivo imprimible?',
    },
    bookshelf_session_timeout_warning: {
      buttontext: 'Cerrar pestaña',
      bottomtext:
        'Para acceder a su libro, cierre esta pestaña y vuelva a abrir el título de Bookshelf.',
      reasonlist:
        '<li>Inactividad en tu sesión de lectura</li><li>Iniciar sesión en otro navegador</li>',
      text: 'Su sesión de lectura ha finalizado y su libro estará cerrado. Esto puede ser causado por:',
      title: 'Tu sesión ha expirado',
    },
    cancel: 'Cancelar',
    continue: 'Continuar',
    document_request_error: 'No se pudo cargar la página. Por favor, actualice y pruebe de nuevo.',
    failed_glossary_term:
      'Error al cargar el término del glosario. Por favor, inténtelo de nuevo más tarde.',
    failed_message: 'Falló al cargar. Por favor, inténtelo de nuevo más tarde.',
    generating_pdf_message: 'Este proceso puede tardar unos momentos. Puede seguir usando su libro y le avisaremos cuando su archivo esté listo para imprimir. Seleccione Aceptar para continuar.',
    glossary_language_change: 'Contenido del glosario actualizado a',
    glossary_language_label: 'Idioma',
    loading_message: 'Cargando...',
    move_down: 'Mover hacia abajo',
    move_left: 'Moverse a la derecha',
    move_right: 'Mover hacia la izquierda',
    move_up: 'Mover hacia arriba',
    reset_image: 'Reiniciar imagen',
    session_ended_error:
      'Tu sesión ha terminado. No podemos guardar sus cambios hasta que este libro se vuelva a abrir.',
    title: {
      credit: 'Crédito',
      footnote: 'Nota',
      glossary: 'Glosario',
      long_description: 'Descripción Larga',
      wiris_editor: 'Editor de Wiris',
      print: 'Imprimir',
      generating_pdf: 'Generando PDF',
    },
    zoom_in_image: 'Zoom en imagen',
    zoom_out_image: 'Alejar imagen',
  },
  teaching_notes: {
    answer: 'Respuesta',
    diff_resource: 'Recurso Diferenciado',
    example: 'Ejemplo',
    teach_notes: 'Notas De Enseñanza',
  },
  contents: {
    contents: 'Contenido',
    contents_dialog: 'Diálogo modal de contenido',
    toc: 'El índice de contenido',
    toc_tab_section: 'Tabla de contenido pestaña sección',
    toc_go_current_location: 'Ir a la ubicación actual',
    toc_current_location: 'Ubicación actual',
    toc_info: 'Si usa su teclado, presione <br /><br /><ul><li>TAB para navegar dentro y fuera del árbol</li><li>FLECHA ABAJO y FLECHA ARRIBA para navegar por el árbol</li><li>FLECHA DERECHA para expandir un grupo contraído</li><li>FLECHA IZQUIERDA para contraer un grupo expandido</li><li>INTRO para seleccionar un elemento dentro de un grupo</li></ul>',
    toc_aria_label: 'Instruciones',
    topics_back_to: 'Regresar a',
    topics_cases: 'Casos',
    topics_algorithms: 'Algoritmos',
    topics_pattern_recognition: 'Reconocimiento de Patrones',
    topics_practice: 'Practicar',
    topics_tip: 'Temas De Alto Valor',
    topics_menu_alert:
      '¡Parece que algo salió mal! Actualice o vuelva a intentarlo más tarde. Si el problema persiste, por favor contáctenos.',
    highlight_tab_section: 'Destacado pestaña sección',
    note_tab_section: 'Nota pestaña sección',
    placemark_tab_section: 'Marcador pestaña sección',
    toc_blank: 'Tabla de contenido está vacía o no se ha podido cargar.',
    highlight_blank: 'Todavía no se ha creado un destacado.',
    highlight_session_ended:
      'La funcionalidad de resaltado no está disponible sin una sesión activa.',
    highlight_tip: 'Todos los destacados',
    dropdown_tip_highlights: 'All Highlights',
    dropdown_all_highlights: 'All Highlights',
    dropdown_detached_highlights: 'Detached Highlights',
    menu_edit_highlights: 'Edit Highlights',
    menu_export_highlights: 'Export Highlights',
    dropdown_tip_notes: 'All Notes',
    dropdown_all_notes: 'All Notes',
    dropdown_detached_notes: 'Detached Notes',
    menu_edit_notes: 'Edit Notes',
    menu_export_notes: 'Export Notes',
    dropdown_tip_placemarks: 'All Placemarks',
    dropdown_all_placemarks: 'All Placemarks',
    dropdown_detached_placemarks: 'Detached Placemarks',
    menu_edit_placemarks: 'Edit Placemarks',
    menu_export_placemarks: 'Export Placemarks',
    orphan_highlight_msg: 'Este destacado esta desacoplado',
    note_blank: 'Todavía no se ha creado una anotación.',
    note_session_ended: 'La funcionalidad de Notes no está disponible sin una sesión activa.',
    note_tip: 'Nota',
    placemark_blank: 'Todavía no se ha colocado un marcador.',
    placemark_session_ended:
      'La función de marcador de posición no está disponible sin una sesión activa.',
    placemark_tip: 'Marcador',
    show_more: 'Muestra más',
    show_less: 'Muestra menos',
    export: 'exportar',
    detached_highlight: 'Este resaltado está separado.',
    detached_tooltip_button: 'Consejo',
    detached_highlight_tip:
      'El texto que ha resaltado para este destacado ya no existe, porque el libro ha sido editado por el editor del libro.',
    detached_note: '3. Esta nota está separada.',
    detached_note_tip:
      'El texto que ha resaltado para esta nota ya no existe, porque el libro ha sido editado por el editor del libro.',
    detached_placemark: 'Esta marca de posición está separada.',
    detached_placemark_tip:
      'El texto que ha resaltado para esta marca de posición ya no existe porque el editor editó el libro.',
  },
  search: {
    search: 'Búsqueda',
    search_dialog: 'Cuadro de diálogo modal de búsqueda',
    display_all_results: 'Mostrar todos los resultados',
    enter_search_term: 'Ingrese su término de búsqueda',
    result: 'resultado',
    results: 'resultos',
    limited_results: 'Limitado a 1,000 resultados. Refina tu consulta.',
    refine_search: 'Refine los términos de búsqueda y vuelva a intentarlo.',
    jump_to_page: 'Ir a',
    text_and_page_number: 'Busque por página o texto',
    result_item: 'resultado de búsqueda',
    loading: 'Cargando...',
    loading_search: 'Carga de contenido de búsqueda',
    loading_failed: 'Error al cargar la búsqueda',
    try_again: 'Inténtalo de nuevo',
  },
  elastic_search: {
    topics: 'Temas',
    quizzes: 'Cuestionarios',
    launchable_topic: 'Tema lanzable: ',
    launchable_quiz: 'Cuestionario lanzable: ',
    search_suggestion: 'Sugerencia de búsqueda: ',
    breadcrumbs_separator: ', en ',
  },
  export: {
    highlights: 'Destacados​',
    export_highlights: 'Exportar Todos Destacados',
    notes: 'Notas',
    export_notes: 'Exportar Todas Notas',
    success_message: 'exportado, con éxito',
    error_message: 'Exportación ha fallado, intente más tarde',
    of: ' de ',
    printed: 'Impreso por el Usuario ',
    title_highlights: 'Destacados​ de',
    title_notes: 'Notas de',
  },
  readspeaker: {
    read_page: 'Leer toda la página',
    read_selected: 'Leer texto seleccionado',
    quick_play_menu: 'Leer altavoz menú de reproducción rápida',
    read_math: 'Leer matemáticas en voz alta',
    open_speed_slider: 'Deslizador de velocidad abierta',
    close_speed_slider: 'Control deslizante de velocidad de cierre',
    speech_speed_slider: 'Control deslizante de velocidad del habla',
    cant_read: 'Esta página no se puede leer.',
    error: 'Error de audio',
    play: 'Toque',
    pause: 'Pausa',
    mute: 'Volumen mudo',
    unmute: 'Volumen de silencio',
    volume: 'Volumen',
    slow_down: 'Disminuya la velocidad de reproducción en un 25%',
    speed_up: 'Acelera la velocidad de reproducción en un 25%',
    buffering: 'Cargando',
    read_aloud: 'Leer en voz alta',
    progress_control: 'Control de progreso',
    volume_control: 'Control del volumen',
    speed: 'Velocidad',
    view_speed_control: 'Control de velocidad',
    speed_control: 'Control de velocidad',
    close: 'Cerrar',
  },
  levelText: {
    level_selection: 'Selección de nivel de leer',
    change_level: 'Cambiar nivel',
    language_selection: 'Selección de lengua',
  },
  errorPage: {
    404: {
      title: 'No podemos encontrar lo que buscas.',
      reason_list:
        '<li>Se ha quitado el recurso solicitado.</li><li>Se ingresó la URL incorrecta.</li>',
      try_list:
        '<li custom-login>Vuelva a intentar el enlace que utiliza para acceder a su contenido.</li><li>Borrar la caché del navegador y el historial.</li><li>Póngase en contacto con su administrador.</li>',
    },
    401: {
      title: 'Algo ha fallado y no puedes ver este contenido.',
      reason_list:
        '<li>Se agotó su tiempo de espera.</li><li>Tiene credenciales incorrectas.</li><li>Tienes otro libro abierto.</li>',
      try_list:
        '<li custom-login>Vuelva a intentar el enlace que utiliza para acceder a su contenido.</li><li>Borrar la caché del navegador y el historial.</li><li>Póngase en contacto con su administrador.</li>',
      aleks: 'No tiene acceso a su eBook en este momento. Cierra esta ventana.',
    },
    403: {
      title: 'Su acceso para ver este recurso ha sido denegado.',
      reason_list:
        '<li>No tiene derechos de acceso a este recurso.</li><li>Tienes otro libro abierto.</li>',
      try_list:
        '<li custom-login>Vuelva a intentar el enlace que utiliza para acceder a su contenido.</li><li>Asegúrese de que ha activado cookies de terceros.</li><li>Eliminar las cookies del navegador.</li><li>Borrar la caché del navegador y el historial.</li><li>Asegúrese de tener acceso a este recurso.</li>',
    },
    500: {
      title: 'Algo salió mal.',
      reason_list:
        '<li>El servidor encontró un error al iniciar tu contenido.</li>',
      try_list:
        '<li custom-login>Vuelva a intentar el enlace que utiliza para acceder a su contenido.</li><li>Borrar la caché del navegador y el historial.</li><li>Póngase en contacto con el soporte de McGraw Hill.</li>',
    },
    sessionTimeout: {
      title: 'Tu sesión ha expirado',
      text: 'Su sesión de lectura ha finalizado y su libro estará cerrado. Cualquier trabajo pendiente se ha guardado, pero deberá volver a abrir el libro.',
      reason: 'Esto puede ser causado por:',
      reason_list:
        '<li>La pestaña del navegador abre más de 4 horas</li><li>Lanzar otro libro en el mismo navegador</li><li>Rastreo entre sitios</li>',
      try: 'Para acceder a su libro, cierre esta pestaña y vuelva a abrir el título.',
      tryBrowserSettings: 'Abre el menú de navegador, selecciona Configuración, selecciona Privacidad, desmarca la casilla para prevenir el rastreo entre sitios. Actualiza tu página e intenta de nuevo.',
      or: 'o',
    },
    whoops: '',
    reason: 'La razón podría ser:',
    try: 'Por favor intenta lo siguiente:',
    partnerCustomer: {
      atd: {
        support:
          '<p class="title">Para obtener ayuda, póngase en contacto con ATD:</p><p class="errorSubText">Llama 800.628.2783 (EE.UU.) o +1.703.683.8100 (internacional), de lunes a viernes, 8 a.m. – 6 p.m. ET.</p><p class="errorSubText">Habla con alguien a través de chat en <a href="https://www.td.org" target="_blank">www.td.org</a>,<br/>de lunes a viernes, 8 a.m. – 5:30 p.m. ET.</p><p class="errorSubText">Correo electrónico en cualquier momento <a href="mailto:customercare@td.org">customercare@td.org</a>.</p>',
        login_again: 'Inicia sesión de nuevo en',
        login_url: 'reader.td.org',
      },
    },
  },
  banner: {
    con: {
      title: 'ReadAnywhere',
      description: 'Abrir McGraw-Hill ePub Reader.',
    },
    atd: {
      title: 'ATD Reader',
      description: 'Abrir ATD ePub Reader.',
    },
    open: 'Abierto',
    download: 'Descargar',
  },
  'font-resizer': {
    change_size: 'Cambia tamaño de fuente',
    increase_size: 'Aumentar el tamaño de la fuente',
    decrease_size: 'Reducir el tamaño de la fuente',
    current_value: 'Nivel {{val}}',
    control_label: 'Cuadro de diálogo Cambiar tamaño de fuente',
    slider_label: 'Control deslizante de cambio de tamaño de fuente',
  },
  'concept-navigator': {
    button_previous: 'Concepto anterior',
    button_current: 'Saltar al concepto',
    button_next: 'Siguiente concepto',
  },
  large_image_navigator: 'Navegador de Imágenes Grandes',
  assignment: {
    submit_btn: 'Presentar Asignación',
    back_assignment_btn: 'Volver a Tarea',
    warning_title: 'Atención!',
    warning_content:
      'Estás a punto de presentar esta tarea, bloqueando tu trabajo. <br> Compruebe que ha completado todas las secciones.',
    warning_page_out_of_range: 'Esta página no es parte de la tarea',
    success_title: ' Asignación Presentada',
    success_content: 'Has enviado correctamente tu asignación.',
    ok_btn: 'Lo tengo!',
    error_title: 'Error',
    error_content:
      'Estamos teniendo problemas para salvar su trabajo. Por favor, inténtelo de nuevo más tarde.',
    error_dialog: 'Cuadro de diálogo Error',
    success_dialog: 'Diálogo de éxito',
    assignment_bar_submitted: 'Su ha sido presentado. Los cambios no se guardarán.',
    submit_k5_btn: 'Presentar',
    k5_warning_content: '¿Estás seguro de que deseas enviar esta tarea?',
    error_missing_widget_index:
      'La asignación no se pudo abrir debido a que falta un índice. <br> Pídale a su maestro que se comunique con el Servicio al cliente para obtener ayuda con el',
    error_reopen_failure: 'La asignación no se pudo abrir. Por favor, intenta de nuevo.',
    review_mode:
      'Estás en modo de revisión. Tus cambios no se guardarán. Los aspectos destacados, las marcas de posición y las notas están inhabilitados.',
  },
  'media-overlay': {
    audio_sync: 'Sincronización de audio',
    play: 'Toque',
    pause: 'Pausa',
    stop: 'Parar',
  },
  widget: {
    success_title: 'Widgets despejados',
    error_title: 'Error',
    error_content:
      'Estamos teniendo problemas para borrar sus datos. Por favor, inténtelo de nuevo más tarde.',
    success_dialog: 'Diálogo de éxito',
    error_dialog: 'Cuadro de diálogo Error',
    ok_btn: 'Lo tengo!',
  },
  firebase: {
    reduced_mode: 'Estás operando en modo reducido.',
    restored: 'Funcionalidad completa ha sido restaurada.',
  },
  language_code: {
    de: 'Deutsche',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
  },
  gif: {
    pause: 'Pausar el GIF',
    paused: 'GIF está en pausa. Reproduce GIF',
    play: 'Reproduce GIF',
    playing: 'GIF se está reproduciendo. Pausar el GIF',
  },
  print: {
    print: 'Imprimir',
    select: 'Selecciona un capitulo para imprimir',
    piracyStatement: 'Algunas páginas de este libro electrónico están disponibles para imprimir teniendo en cuenta la protección de la propiedad intelectual de los autores y del editor.',
    file_ready: 'Su archivo imprimible está listo:',
    error: 'Hubo un error al recuperar tu PDF. Por favor, inténtalo de nuevo o contacta al soporte.',
  },
  ai_assist: {
    button: 'Asistencia de IA',
  },
  orphan_annotations: {
    title: '¡Anotaciones desconectadas detectadas!',
    paragraph_step_1: 'Algunas de tus anotaciones ya no enlazan con el texto debido a cambios de contenido. Permanecerán en su menú Contenidos y podrán identificarse mediante un',
    paragraph_step_2: 'icono. Sin embargo, no vincularán directamente a ningún contenido del libro.',
    do_not_show_message_again: 'No mostrar este mensaje de nuevo',
    accept_message: 'Entiendo',
  },
  a11y: {
    page_navigation: 'Titulo',
  },
};
