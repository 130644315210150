<div class="drawer-header">
  <h2 translate>contents.contents</h2>

  <button
    data-automation-id="contents-drawer-close"
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    placement="bottom"
    [title]="'shared.close' | translate"
    rdrxHideOnEscape
    [mheTooltip]="'shared.close' | translate"
    (click)="closeEvent.emit()"
  >
    <i class="dpg-icon dpg-icon-system-close"></i>
  </button>
</div>

<!-- tab content -->
<mat-tab-group
  disableRipple
  animationDuration="0ms"
  class="rdrx-tabs mhe-a11y flex-labels drawer-body"
  [class.hide-tabs]="single$ | async"
  rdrxHideOnEscape
  (selectedTabChange)="onTabChange($event)"
  rdrxMatTabGroupDirective
  (matTabGroupBlur)="onFocusChange($event)"
>
  <!-- TOC tab -->
  <mat-tab *ngIf="(config$ | async)?.toc" label="ToC">
    <ng-template mat-tab-label>
      <div
        #tocTab="mheTooltip"
        data-automation-id="table-of-contents-tab"
        [mheTooltip]="'contents.toc' | translate"
        [rdrxMatTabTooltip]="tocTab"
      >
        <i class="dpg-icon dpg-icon-listview"></i>
        <mat-icon
          *ngIf="k5$ | async"
          class="rdrx-k5-icon"
          svgIcon="k5:listview"
        ></mat-icon>
      </div>
    </ng-template>

    <!-- Material Tree TOC -->
    <ng-container
      *ngIf="((featureTogglesService?.materialTreeToc$ | async) === true && (k5$ | async) === false); else oldTOC"
    >
      <rdrx-reader-material-tree-toc></rdrx-reader-material-tree-toc>
    </ng-container>

    <!-- Old TOC tab (soon to be deprecated) -->
    <ng-template #oldTOC >
      <reader-core-toc></reader-core-toc>
    </ng-template>
  </mat-tab>

  <!-- Topics Tab -->
  <mat-tab *ngIf="(config$ | async)?.topicsEndpoint" label="Topics">
    <ng-template mat-tab-label>
      <div
        #topicsTab="mheTooltip"
        data-automation-id="topics-tab"
        [mheTooltip]="'contents.topics_tip' | translate"
        [rdrxMatTabTooltip]="topicsTab"
      >
        <i class="dpg-icon dpg-icon-system-lesson"></i>
      </div>
    </ng-template>
    <ng-template matTabContent>
      <rdrx-topics></rdrx-topics>
    </ng-template>
  </mat-tab>

  <!-- Highlights Tab -->
  <mat-tab *ngIf="(config$ | async)?.highlights" label="Highlights">
    <ng-template mat-tab-label>
      <div
        #highlightsTab="mheTooltip"
        data-automation-id="highlights-tab"
        [mheTooltip]="'contents.highlight_tip' | translate"
        [rdrxMatTabTooltip]="highlightsTab"
      >
        <i class="dpg-icon dpg-icon-highlighter"></i>
        <mat-icon *ngIf="k5$ | async" class="rdrx-k5-icon" svgIcon="k5:highlighter"></mat-icon>
      </div>
    </ng-template>

    <ng-template matTabContent>
      <rdrx-highlight-list></rdrx-highlight-list>
    </ng-template>
  </mat-tab>

  <!-- Notes Tab -->
  <mat-tab *ngIf="(config$ | async)?.notes" label="Notes">
    <ng-template mat-tab-label>
      <div
        #notesTab="mheTooltip"
        data-automation-id="notes-tab"
        [mheTooltip]="'contents.note_tip' | translate"
        [rdrxMatTabTooltip]="notesTab"
      >
        <i class="dpg-icon dpg-icon-notepad"></i>
        <mat-icon *ngIf="k5$ | async" class="rdrx-k5-icon" svgIcon="k5:notepad"></mat-icon>
      </div>
    </ng-template>

    <ng-template matTabContent>
      <rdrx-note-list></rdrx-note-list>
    </ng-template>
  </mat-tab>

  <!-- Placemarks Tab -->
  <mat-tab *ngIf="(config$ | async)?.placemarks" label="Placemarks">
    <ng-template mat-tab-label>
      <div
        #placemarksTab="mheTooltip"
        role="button"
        data-automation-id="placemarks-tab"
        [mheTooltip]="'contents.placemark_tip' | translate"
        [rdrxMatTabTooltip]="placemarksTab"
      >
        <i class="dpg-icon placemark-icon"></i>
        <mat-icon *ngIf="k5$ | async" class="rdrx-k5-icon" svgIcon="k5:bookmark"></mat-icon>
      </div>
    </ng-template>

    <ng-template matTabContent>
      <rdrx-placemark-list></rdrx-placemark-list>
    </ng-template>
  </mat-tab>
</mat-tab-group>
