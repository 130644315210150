<div role="group" aria-label="Media Controls">
  <div *ngIf="enableTTS" class="button-group">
    <rdrx-page-tts-controls class="tts-controls"></rdrx-page-tts-controls>
  </div>

  <div
    *ngIf="showReadSpeakerVoicePick"
    class="button-group button-voice-picker"
  >
    <rdrx-tts-voice-picker></rdrx-tts-voice-picker>
  </div>

  <div *ngIf="isAiAssistOffered" class="button-group button-group-sm ai-assist">
    <button
      mhe-button
      class="aiAssistButton"
      [purpose]="ButtonPurpose.Icon"
      (click)="onClickAiAssist()"
      [mheTooltip]="'ai_assist.button' | translate"
    >
      <i class="ai-assist ai-assist-icon bigger"></i>
    </button>
    <span class="badge">BETA</span>
  </div>
</div>

<div class="button-group button-group-sm" *ngIf="readerStore.showAnnotateButton$ | async">
  <button
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    (click)="annotateSelectionOnMobile()"
    [mheTooltip]="'navigation.highlight_button' | translate"
  >
    <i class="dpg-icon dpg-icon-highlighter"></i>
  </button>
</div>

<div *ngIf="isFixedLayout || (isFplSpine$ | async)" class="button-group bottom-button-group">
  <button
    *ngIf="isDoubleSpread && (ribac$ | async) === false && isSinglePageViewOnly === false"
    [disabled]="isAlbumModeForced$ | async"
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    [mheTooltip]="albumModeLabel"
    (click)="onToggleAlbumMode()"
  >
    <i
      class="dpg-icon"
      [class.dpg-icon-box-one-arrow-in-left]="!albumMode"
      [class.dpg-icon-boxes-two-arrow-in-left]="albumMode"
    ></i>
  </button>

  <ng-container *ngIf="albumMode || !isDoubleSpread">
    <button
      [disabled]="!increaseEpubZoomEnabled"
      mhe-button
      [purpose]="ButtonPurpose.Icon"
      [mheTooltip]="'iframe.zoom_in_page' | translate"
      (click)="increaseZoomLevel($event)"
    >
      <i class="dpg-icon dpg-icon-magnifying-glass-plus-symbol"></i>
    </button>

    <p class="zoom-display" [aria-label]="zoomInText" aria-live="polite">{{ zoomDisplay }}</p>

    <button
      [disabled]="!decreaseEpubZoomEnabled"
      mhe-button
      [purpose]="ButtonPurpose.Icon"
      [mheTooltip]="'iframe.zoom_out_page' | translate"
      (click)="decreaseZoomLevel($event)"
    >
      <i class="dpg-icon dpg-icon-magnifying-glass-minus-symbol"></i>
    </button>

    <ng-container *ngIf="zoomLevel > 1">
      <button
        class="btn-arrow-icon"
        mhe-button
        [disabled]="!enableUpArrow"
        [purpose]="ButtonPurpose.Icon"
        (click)="moveEpubOnClick('up')"
        [mheTooltip]="'iframe.move_page_up' | translate"
      >
        <i class="dpg-icon dpg-icon-up-arrow"></i>
      </button>

      <button
        class="btn-arrow-icon"
        mhe-button
        [disabled]="!enableDownArrow"
        [purpose]="ButtonPurpose.Icon"
        (click)="moveEpubOnClick('down')"
        [mheTooltip]="'iframe.move_page_down' | translate"
      >
        <i class="dpg-icon dpg-icon-down-arrow"></i>
      </button>

      <button
        class="btn-arrow-icon"
        mhe-button
        [disabled]="!enableRightArrow"
        [purpose]="ButtonPurpose.Icon"
        (click)="moveEpubOnClick('right')"
        [mheTooltip]="'iframe.move_page_right' | translate"
      >
        <i class="dpg-icon dpg-icon-right-arrow"></i>
      </button>

      <button
        class="btn-arrow-icon"
        mhe-button
        [disabled]="!enableLeftArrow"
        [purpose]="ButtonPurpose.Icon"
        (click)="moveEpubOnClick('left')"
        [mheTooltip]="'iframe.move_page_left' | translate"
      >
        <i class="dpg-icon dpg-icon-system-left-arrow"></i>
      </button>
    </ng-container>
  </ng-container>
</div>
