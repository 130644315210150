<div
  class="header go-to-active"
  (click)="focusCurrentNode()"
  (keydown.enter)="focusCurrentNode()"
  (keydown.space)="focusCurrentNode()"
  #header
>
  <div class="header-content">
    <h3 style="padding: 0 8px; margin-top: 5px" translate>contents.toc</h3>
    <reader-ui-info-component></reader-ui-info-component>
  </div>
  <a
    href="javascript:void(0)"
    data-automation-id="go-to-current-location"
    class="ahe-ui-link"
  >
    {{ "contents.toc_go_current_location" | translate }}
  </a>
</div>

<div class="tree-container">
  <mat-tree
    #tree
    [dataSource]="dataSource"
    [childrenAccessor]="childrenAccessor"
    role="tree"
    aria-label="Table of contents"
  >
    <!-- Leaf Node Template -->
    <mat-tree-node
      *matTreeNodeDef="let node; let level = level"
      class="leaf-node"
      [attr.data-node-id]="node.id"
      [attr.data-level]="level"
      matTreeNodePadding
      [attr.data-hash]="getHashFromHref(node.tocItem?.href)"
      (click)="selectedNode(node)"
      (keydown.enter)="selectedNode(node)"
      (keydown.space)="selectedNode(node)"
      [ngClass]="{ 'expanded-node': tree.isExpanded(node) }"
      [attr.aria-current]="isCurrentPageLocation(node) ? 'page' : null"
      [attr.aria-label]="node.name"
    >
      <div class="tree-node-content">
        <button mat-icon-button disabled tabindex="-1"></button>
        <span class="node-content" aria-hidden="true">{{ node.name }}</span>
        <span
          *ngIf="isCurrentPageLocation(node)"
          class="current-page-indicator"
          [class.animate-location-icon]="animateLocationIcon"
        ></span>
      </div>
    </mat-tree-node>

    <!-- Expandable Node Template -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild; let level = level"
      [attr.data-node-id]="node.id"
      [attr.data-level]="level"
      matTreeNodePadding
      tabindex="0"
      [attr.data-spine-pos]="node.tocItem?.spinePos"
      [attr.data-hash]="getHashFromHref(node.tocItem?.href)"
      (click)="selectedNode(node)"
      (keydown.enter)="selectedNode(node)"
      (keydown.space)="selectedNode(node)"
      (keydown.arrowright)="expandNode(node, $event)"
      (keydown.arrowleft)="collapseNode(node, $event)"
      [ngClass]="{ 'expanded-node': tree.isExpanded(node) }"
      [attr.aria-expanded]="tree.isExpanded(node) ? 'true' : 'false'"
      [attr.aria-current]="isCurrentPageLocation(node) ? 'page' : null"
      [attr.aria-label]="node.name"
    >
      <div class="tree-node-content">
        <div class="toggle-container" tabindex="-1">
          <div class="toggle-area" (click)="onToggleButtonClick(node, $event)">
            <button
              mat-icon-button
              disableRipple
              class="toggle-children-wrapper"
              (mousedown)="$event.preventDefault()"
              tabindex="-1"
              [attr.aria-label]="
                tree.isExpanded(node) ? 'Collapse node' : 'Expand node'
              "
            >
              <span
                class="shevron-icon"
                [class.expanded]="tree.isExpanded(node)"
              ></span>
            </button>
          </div>
          <span class="vertical-divider"></span>
        </div>
        <span class="node-content" aria-hidden="true">{{ node.name }}</span>
        <span
          *ngIf="isCurrentPageLocation(node)"
          class="current-page-indicator"
          [class.animate-location-icon]="animateLocationIcon"
        ></span>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
