import {
  FlexibleConnectedPositionStrategy,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, Renderer2 } from '@angular/core';
import { combineLatest, fromEvent, merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  first,
  map,
  startWith,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiAnnotation } from '@mhe/reader/models';
import { AnnotationsContextMenuComponent } from '../annotations-context-menu.component';
import { AnnotationsContextMenuConfig } from '../annotations-context-menu.model';
import {
  ANNOTATION,
  ANNOTATIONS_CONTEXT_MENU_CONFIG,
  OVERLAY_REF,
} from '../annotations-context-menu.tokens';
import { AnnotationsContextMenuStore } from '../state/annotations-context-menu.store';
import { DeviceService } from '@mhe/reader/common';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

When this is opened all the way at the edge, it can possibly open outside of the host component.  This is because
  the FlexibleConnectedPositionStrategy uses the document as it's bounding container.  I attempted to initialize
  a strategy manually, like so:
  new FlexibleConnectedPositionStrategy(anchorNode, iframeDoc, ...)
  I was able to make this work consistently (and nicer than it currently works) when overlayY was 'top' (meaning
  the positioning is measured from the top) but when overlayY is 'bottom', the positioning was messed up by
  amounts varying depending on how wide the window was.  I think we could solve this if we wanted to create our own
  position strategy, but that seemed like a time-sink given our needs right now - thus I'm leaving a note.
  ALWAYS leave a note. - J Walter Weatherman

The arrow shown above or below the overlay is fixed to the center of the overlay.  When you open the overlay near
  the edges, the arrow may not point to the beginning or end of the selection as expected because the width of the
  overlay hits the edge of the container

 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

@Injectable()
export class AnnotationsOverlayService {
  private readonly _forcedClose$ = new Subject<void>();
  private resizeObserver?: ResizeObserver;

  constructor(
    private readonly renderer: Renderer2,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly annotationStore: AnnotationsContextMenuStore,
    private readonly deviceService: DeviceService,
  ) {}

  handleTouchScreenAnchor(iframe: HTMLIFrameElement, range: Range, anchorNode: HTMLSpanElement): void {
    // initially position the anchor node
    this.setupTouchscreenAnchorNode(iframe, range, anchorNode);

    // insert the anchor node into the window once
    document.body.appendChild(anchorNode);
  }

  openContextMenu(
    annotationSeed: Partial<ApiAnnotation>,
    showAbove: boolean,
    range: Range,
    iframe: HTMLIFrameElement,
    config: AnnotationsContextMenuConfig = {
      highlights: true,
      placemarks: true,
      notes: true,
      readspeaker: true,
      isAiAssistOffered: false,
    },
  ): Observable<ApiAnnotation> {
    // create an anchor node to position the overlay against
    const anchorNode = this.createAnchorNode();

    if (this.deviceService.isTouchScreen) {
      this.handleTouchScreenAnchor(iframe, range, anchorNode);
    } else {
      this.insertAnchorNode(range, anchorNode, showAbove);
    }

    const positionStrategy = this.setupPositionStrategy(
      anchorNode,
      showAbove,
      iframe,
    );

    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      hasBackdrop: true,
      backdropClass: 'annotation-context-menu-backdrop',
    });

    const tokens = this.createTokenMap(overlayRef, annotationSeed, config);
    const portal = this.createComponentPortal(tokens);

    overlayRef.attach(portal);
    positionStrategy.apply();

    this.observeOverlaySizeChanges(overlayRef);

    const updatePositionStrategy$: Observable<any> = fromEvent(
      iframe?.ownerDocument?.defaultView as any,
      'resize',
    ).pipe(
      takeUntil(overlayRef.detachments()),
      startWith({}),
      debounceTime(100),
      tap(() => {
        // update the anchor node position
        if (this.deviceService.isTouchScreen) {
          this.handleTouchScreenAnchor(iframe, range, anchorNode);
        }

        // update the overlay position strategy
        const newPositionStrategy = this.setupPositionStrategy(
          anchorNode,
          showAbove,
          iframe,
        );
        overlayRef.updatePositionStrategy(newPositionStrategy);
      }),
    );

    const onClose$: Observable<ApiAnnotation> = this.close$(overlayRef).pipe(
      withLatestFrom(this.annotationStore.annotation$),
      map(([, annotation]) => annotation),
      finalize(() => {
        overlayRef.detach();
        anchorNode.remove();
      }),
    );
    return combineLatest([updatePositionStrategy$, onClose$]).pipe(
      map(([, close]: [any, ApiAnnotation]) => close),
      filter((annotation) => !!annotation),
    );
  }

  // Close the modal on backdrop click, esc key and any other detatchments from elsewhere (like in the component we've launched)
  private close$(overlayRef: OverlayRef): Observable<any> {
    return merge(
      overlayRef.backdropClick(),
      overlayRef
        .keydownEvents()
        .pipe(filter((event) => event.key === 'Escape')),
      overlayRef.detachments(),
      this._forcedClose$,
    ).pipe(first());
  }

  close(): void {
    this._forcedClose$.next();
  }

  // Create an anchor node that will be used for the overlay to be "connected" to
  private createAnchorNode(): HTMLSpanElement {
    const anchorNode: HTMLSpanElement = this.renderer.createElement('span');
    anchorNode.classList.add('overlay-anchor-to-range');
    return anchorNode;
  }

  private observeOverlaySizeChanges(overlayRef: OverlayRef): void {
    const overlayElement = overlayRef.overlayElement;
    this.resizeObserver = new ResizeObserver(() => {
      overlayRef.updatePosition();
    });
    this.resizeObserver.observe(overlayElement);
  }

  calculatePosition(
    iframe: HTMLIFrameElement,
    anchorNode: HTMLSpanElement,
    showAbove: boolean,
  ): {
      originY: 'top' | 'center' | 'bottom'
      overlayY: 'top' | 'center' | 'bottom'
      offsetX: number
      offsetY: number
      anchorHeight: number
    } {
    let offsetX = 0;
    let offsetY = 0;
    let originY: 'top' | 'center' | 'bottom' = 'center';
    let anchorHeight = 0;
    let anchorOffset = 0;

    const overlayY: 'top' | 'center' | 'bottom' = showAbove ? 'bottom' : 'top';

    if (this.deviceService.isTouchScreen) {
      // strategy position is connected to a span absolutely positioned in the main window

      // only need to account for the height of the centered arrow from the context menu
      // this is a fixed dimension of an element using ::after to inject an svg arrow
      offsetY = showAbove ? -10 : 10;
      offsetX = 0;

      // determine where against the span the overlay will be positioned
      originY = showAbove ? 'top' : 'bottom';

      // generally the overlay is anchored to the span, but in Firefox we need to use the span's position
      // TODO test this in Firefox without the special handling
      anchorHeight = anchorNode.getBoundingClientRect().height;
    } else {
      anchorHeight = anchorNode.getBoundingClientRect().height;

      anchorOffset = showAbove ? anchorHeight * -1 : anchorHeight;
      offsetY = iframe.getBoundingClientRect().top + anchorOffset;
      offsetX = iframe.getBoundingClientRect().left;
      originY = 'center';
    }

    return { offsetX, offsetY, originY, overlayY, anchorHeight };
  }

  // Use the flexibleConnectedTo position strategy to open the overlay in relation to the beginning or end of the selection (where the anchor node is inserted)
  private setupPositionStrategy(
    anchorNode: HTMLSpanElement,
    showAbove: boolean,
    iframe: HTMLIFrameElement,
  ): FlexibleConnectedPositionStrategy {
    // the panel class determines the arrow direction
    const defaultPanelClass = 'annotations-overlay-panel';

    const {
      offsetX,
      offsetY,
      originY,
      overlayY,
      anchorHeight,
    } = this.calculatePosition(iframe, anchorNode, showAbove);

    const panelClass = showAbove
      ? [defaultPanelClass, `${defaultPanelClass}-arrow-down`]
      : [defaultPanelClass, `${defaultPanelClass}-arrow-up`];

    const origin = this.getOrigin(anchorNode, anchorHeight);

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withViewportMargin(60)
      .withPush()
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'center',
          originY,
          overlayX: 'center',
          overlayY,
          panelClass,
          offsetY,
          offsetX,
        },
      ]);

    return positionStrategy;
  }

  private createTokenMap(
    overlayRef: OverlayRef,
    annotation: Partial<ApiAnnotation>,
    config: AnnotationsContextMenuConfig,
  ): WeakMap<InjectionToken<any>, any> {
    return new WeakMap<InjectionToken<any>, any>([
      [OVERLAY_REF, overlayRef],
      [ANNOTATION, annotation],
      [ANNOTATIONS_CONTEXT_MENU_CONFIG, config],
    ]);
  }

  // Add the overlay reference to the injector so the launched component can leverage it to close. Much like DialogRef
  private createComponentPortal(
    tokens: WeakMap<InjectionToken<any>, any>,
  ): ComponentPortal<AnnotationsContextMenuComponent> {
    const portalInjector = new PortalInjector(this.injector, tokens);
    return new ComponentPortal(
      AnnotationsContextMenuComponent,
      null,
      portalInjector,
    );
  }

  // setup the anchor node to match the range size and position in touch screen devices
  private setupTouchscreenAnchorNode(
    iframe: HTMLIFrameElement,
    range: Range,
    anchorNode: HTMLElement,
  ): void {
    // position starts with the boundaries of the range
    // this is a rectangle enclosing the union of the bounding rectangles for all the elements in the range
    // see https://developer.mozilla.org/en-US/docs/Web/API/Range/getBoundingClientRect
    const rect = range.getBoundingClientRect();

    // position accounts for the iframe position
    const iframeTop = iframe.getBoundingClientRect().top;
    const iframeLeft = iframe.getBoundingClientRect().left;

    // this span is sized and positioned to match the range
    anchorNode.style.position = 'absolute';
    anchorNode.style.width = `${rect.width}px`;
    anchorNode.style.height = `${rect.height}px`;
    anchorNode.style.top = `${rect.top + iframeTop}px`;
    anchorNode.style.left = `${rect.left + iframeLeft}px`;

    // visual confirmation of dummy element to position against
    // anchorNode.style.backgroundColor = 'magenta';
    // anchorNode.style.opacity = '0.5';
    // anchorNode.style.zIndex = '1';
  }

  // Insert the anchor node at the beginning or end of the selection in desktop devicesß
  private insertAnchorNode(
    range: Range,
    anchorNode: HTMLElement,
    toStart: boolean,
  ): void {
    const rangeCopy = range.cloneRange();
    rangeCopy.collapse(toStart);
    rangeCopy.insertNode(anchorNode);
  }

  getOrigin(
    anchorNode: HTMLElement,
    anchorHeight: number,
  ): HTMLElement | FlexibleConnectedPositionStrategyOrigin {
    if (this.deviceService.browserName === 'Firefox') {
      return {
        x: anchorNode.getBoundingClientRect().x,
        y: anchorNode.getBoundingClientRect().y,
        width: 0,
        height: anchorHeight,
      };
    }

    return anchorNode;
  }
}
