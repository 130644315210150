import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TocItem } from '@mhe/reader/models';
import { MatTreeModule, MatTree, MatTreeNode } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReaderStore } from '../reader/state/reader.store';
import { NavigationStore } from '../navigation/state/navigation.store';
import { TocStore } from '@mhe/reader/components/toc';
import { withLatestFrom, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as tocActions from '@mhe/reader/components/toc';
import { TranslateModule } from '@ngx-translate/core';
import { InfoComponentModule } from '../info-component/info-component.module';

export interface TreeNode {
  id?: number
  name: string
  children?: TreeNode[]
  tocItem?: TocItem
}

export enum TocNodeContentType {
  Exhibit = 'exhibit',
  Anchor = 'anchor',
}

@Component({
  selector: 'rdrx-reader-material-tree-toc',
  templateUrl: './reader-material-tree-toc.component.html',
  styleUrls: ['./reader-material-tree-toc.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InfoComponentModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class MaterialTreeTocComponent implements OnInit, OnDestroy {
  @Input() dataSource: TreeNode[] = [];
  currentSpinePos: number | null = null;
  currentTargetHash: string | null = null;
  selectedNodeItem: TreeNode | null = null;
  lastSelectedParent: TreeNode | null = null;
  activeFocusedNode: HTMLElement | null = null;
  animateLocationIcon = false;
  userHasSelectedNode = false;
  nodeIdCounter = 0;
  manualSelectionActive: boolean = false;

  // Returns the children of a node.
  childrenAccessor = (node: TreeNode): TreeNode[] => node.children ?? [];
  // Checks whether a node has children.
  hasChild = (_: number, node: TreeNode): boolean =>
    !!node.children && node.children.length > 0;

  @ViewChildren(MatTreeNode, { read: ElementRef }) treeNodes!: QueryList<ElementRef>;
  @ViewChild('tree') tree!: MatTree<TreeNode>;
  @ViewChild('header') headerEl!: ElementRef;

  private readonly destroy$ = new Subject<void>();

  /**
   * Generates a unique node ID.
   * @returns A unique numeric ID for a node.
   */
  private generateNodeId(): number {
    return ++this.nodeIdCounter;
  }

  constructor(
    private readonly readerStore: ReaderStore,
    private readonly navigationStore: NavigationStore,
    private readonly tocStore: TocStore,
  ) {}

  /**
   * Lifecycle hook that is called after the component's data-bound properties have been initialized.
   * Subscribes to book data changes and navigation index updates to keep the TOC tree and current location in sync.
   */
  ngOnInit(): void {
    // Subscribe to book data updates and refresh the TOC tree.
    this.readerStore.book$
      .pipe(takeUntil(this.destroy$))
      .subscribe((bookData) => {
        if (bookData.toc) {
          this.dataSource = this.transformBookTocData(bookData.toc);
        }
      });

    // Listen for navigation changes and update the current spine position and target node.
    this.navigationStore.index$
      .pipe(
        withLatestFrom(this.navigationStore.map$),
        map(([index, indexMap]) => ({ index, indexMap })),
        takeUntil(this.destroy$),
      )
      .subscribe(({ index, indexMap }) => {
        // Determine the current spine position and update currentTargetHash.
        const currentLocationSpineIndexStr = Object.keys(indexMap).find(
          key => indexMap[key] === index,
        );
        this.currentSpinePos = currentLocationSpineIndexStr
          ? Number(currentLocationSpineIndexStr)
          : null;

        // Get the target hash based on the current spine position.
        const path = this.currentSpinePos !== null ? this.findNodePath(this.dataSource, this.currentSpinePos) : null;
        const newTargetHash = this.getHashFromHref(path?.[path.length - 1]?.tocItem?.href);

        if (!this.manualSelectionActive) {
          this.currentTargetHash = newTargetHash;
          this.selectedNodeItem = path?.[path.length - 1] ?? null;
        }

        // Collapse all nodes on page change. Use a safe-check in case 'tree' is not yet available.
        if (this.tree) {
          this.tree.collapseAll();
        }

        // Expand to current node upon page change
        this.focusCurrentNode();
      });
  }

  /**
   * Collapses the specified node if it is expanded. If the node is already collapsed,
   * moves focus to its parent node.
   *
   * @param node - The tree node to collapse.
   * @param event - (Optional) The keyboard event that triggered this action.
   */
  collapseNode(node: TreeNode, event?: KeyboardEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.tree.isExpanded(node)) {
      this.tree.toggle(node);
    } else {
      // If the node is already collapsed, move focus to its parent node (if available).
      const nodePath = this.findNodePathByRef(this.dataSource, node);
      if (nodePath && nodePath.length > 1) {
        const parent = nodePath[nodePath.length - 2];
        requestAnimationFrame(() => {
          const parentElem = this.treeNodes.find(elem =>
            elem.nativeElement.getAttribute('data-node-id') === parent.id?.toString(),
          );
          if (parentElem) {
            parentElem.nativeElement.focus();
            this.activeFocusedNode = parentElem.nativeElement;
          }
        });
      }
    }
  }

  /**
   * Expands the specified node if it is not already expanded. If the node is already expanded
   * and has children, moves focus to its first child.
   *
   * @param node - The tree node to expand.
   * @param event - (Optional) The keyboard event that triggered this action.
   */
  expandNode(node: TreeNode, event?: KeyboardEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!this.tree.isExpanded(node) && this.hasChild(0, node)) {
      // Expand the node.
      this.tree.toggle(node);
    } else if (this.tree.isExpanded(node) && this.hasChild(0, node)) {
      // If already expanded, move focus to the first child.
      requestAnimationFrame(() => {
        const firstChildId = node.children?.[0].id;
        if (firstChildId != null) {
          const firstChildElem = this.treeNodes.find(elem =>
            elem.nativeElement.getAttribute('data-node-id') === firstChildId.toString(),
          );
          if (firstChildElem) {
            firstChildElem.nativeElement.focus();
            this.activeFocusedNode = firstChildElem.nativeElement;
          }
        }
      });
    }
  }

  /**
   * Extracts and returns the hash fragment from an href attribute.
   *
   * @param href - The href string that may contain a hash.
   * @returns The hash fragment (without the '#' character), or null if none exists.
   */
  private getHashFromHref(href?: string): string | null {
    return href?.includes('#') ? href.split('#')[1] : null;
  }

  /**
   * Determines the target hash for focusing in the TOC.
   *
   * @returns The target hash string, or null if no target can be determined.
   */
  getTargetHash(): string | null {
    if (this.currentTargetHash) {
      return this.currentTargetHash;
    }
    if (this.currentSpinePos !== null) {
      const path = this.findNodePath(this.dataSource, this.currentSpinePos);
      if (path?.length) {
        return this.getHashFromHref(path[path.length - 1].tocItem?.href);
      }
    }
    return null;
  }

  /**
   * Checks if the provided node corresponds to the current page location.
   * If a node has been manually selected, that node is marked as current.
   *
   * @param node - The tree node to verify.
   * @returns True if the node represents the current page location; false otherwise.
   */
  isCurrentPageLocation(node: TreeNode): boolean {
    const targetHash = this.getTargetHash();
    if (!targetHash) {
      return false;
    }
    if (this.selectedNodeItem) {
      return this.selectedNodeItem === node;
    }
    const nodePath = this.findNodePathByHash(this.dataSource, targetHash);
    return nodePath ? node === nodePath[nodePath.length - 1] : false;
  }

  /**
   * Handles clicks on a node's toggle button by toggling its expansion state
   * and managing focus accordingly.
   *
   * @param node - The tree node associated with the toggle button.
   * @param event - The mouse event triggered by the click.
   */
  onToggleButtonClick(node: TreeNode, event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const toggleButtonElem = event.currentTarget as HTMLElement;
    const parentTreeNode = toggleButtonElem.closest('mat-tree-node') as HTMLElement;

    this.tree.toggle(node);

    setTimeout(() => {
      const activeEl = document.activeElement as HTMLElement;
      if (!activeEl?.closest('mat-tree')) {
        if (this.activeFocusedNode && this.activeFocusedNode !== parentTreeNode) {
          this.activeFocusedNode.setAttribute('tabindex', '-1');
        }
        parentTreeNode.setAttribute('tabindex', '0');
        this.activeFocusedNode = parentTreeNode;
      }
    }, 100);
  }

  /**
   * Focuses on the target node in the TOC.
   * Expands all parent nodes and scrolls the target node into view.
   * A bookmark animation is always triggered to highlight the current location.
   */
  focusCurrentNode(): void {
    const targetHash = this.getTargetHash();
    if (!targetHash) {
      return;
    }

    // Trigger bookmark animation.
    this.animateLocationIcon = true;
    setTimeout(() => {
      this.animateLocationIcon = false;
    }, 1000);

    // Determine the path to the target node.
    let nodePath: TreeNode[] | null = null;
    if (
      this.selectedNodeItem &&
      this.getHashFromHref(this.selectedNodeItem.tocItem?.href) === targetHash
    ) {
      nodePath = this.findNodePathByRef(this.dataSource, this.selectedNodeItem);
    } else {
      nodePath = this.findNodePathByHash(this.dataSource, targetHash);
    }
    if (!nodePath) {
      return;
    }

    // Expand all parent nodes along the found path.
    nodePath.slice(0, -1).forEach(node => {
      if (!this.tree.isExpanded(node)) {
        this.tree.toggle(node);
      }
    });

    // Scroll the target node into view and set focus after a 100 ms delay.
    setTimeout(() => {
      let targetNodeElem: ElementRef | undefined;
      if (this.selectedNodeItem?.id) {
        targetNodeElem = this.treeNodes.find(nodeElem =>
          nodeElem.nativeElement.getAttribute('data-node-id') === this.selectedNodeItem?.id?.toString(),
        );
      }
      if (!targetNodeElem) {
        targetNodeElem = this.treeNodes.find(nodeElem =>
          nodeElem.nativeElement.getAttribute('data-hash') === targetHash,
        );
      }
      if (targetNodeElem) {
        targetNodeElem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        targetNodeElem.nativeElement.focus();
        // Update tabindex for accessibility: only the target node is focusable.
        this.treeNodes.forEach(nodeElem => {
          nodeElem.nativeElement.setAttribute('tabindex', nodeElem === targetNodeElem ? '0' : '-1');
        });
      }
    }, 100);
  }

  /**
   * Handles a manual selection of a node by the user.
   * Updates the target hash and dispatches a TOC selection action.
   *
   * @param node - The tree node that was manually selected.
   */
  selectedNode(node: TreeNode): void {
    if (node.tocItem?.spinePos != null) {
      const hash = this.getHashFromHref(node.tocItem?.href);
      this.currentTargetHash = hash;
      this.userHasSelectedNode = true;
      this.selectedNodeItem = node;
      // Mark manual selection as active for a brief period.
      this.manualSelectionActive = true;
      setTimeout(() => {
        this.manualSelectionActive = false;
      }, 500);
      this.tocStore.dispatch(tocActions.tocItemSelected({ tocItem: node.tocItem }));
      // Update the browser tab title based on the node selection.
      this.updateTabTitle(node);
    }
  }

  /**
   * Updates the browser tab title based on the selected node.
   * If a parent node is selected, its name is used.
   * If a leaf node is selected, the name of its immediate parent (if available) is used.
   */
  private updateTabTitle(node: TreeNode): void {
    const nodeContentType = node.tocItem?.nodeName as TocNodeContentType;
    if (nodeContentType === TocNodeContentType.Exhibit) {
      this.lastSelectedParent = node;
      document.title = node.name;
      return;
    }

    if (node.children && node.children.length > 0) {
      // Node is a parent; use its own name.
      this.lastSelectedParent = node;
      document.title = node.name;
    } else {
      // Node is a leaf. Find its immediate parent.
      const nodePath = this.findNodePathByRef(this.dataSource, node);
      if (nodePath && nodePath.length > 1) {
        const parentNode = nodePath[nodePath.length - 2];
        this.lastSelectedParent = parentNode;
        document.title = parentNode.name;
      } else {
        this.lastSelectedParent = node;
        document.title = node.name;
      }
    }
  }

  /**
   * Recursively searches for the path to a node with the specified spine position.
   *
   * @param nodes - The list of nodes to search through.
   * @param spinePos - The spine position to match.
   * @returns An array representing the path from the root to the matching node, or null if not found.
   */
  private findNodePath(nodes: TreeNode[], spinePos: number): TreeNode[] | null {
    for (const node of nodes) {
      if (node.tocItem?.spinePos === spinePos) {
        return [node];
      }
      if (node.children?.length) {
        const subPath = this.findNodePath(node.children, spinePos);
        if (subPath) {
          return [node, ...subPath];
        }
      }
    }
    return null;
  }

  /**
   * Recursively finds the path to a node that matches the given hash.
   * If exactly one leaf node (a node with no children) exists among the matches,
   * that path is returned. Otherwise, the first matching path is returned.
   *
   * @param nodes - The list of nodes to search.
   * @param targetHash - The hash value to match against the node's href.
   * @returns An array representing the path to the matching node, or null if not found.
   */
  private findNodePathByHash(nodes: TreeNode[], targetHash: string): TreeNode[] | null {
    for (const node of nodes) {
      const nodeHash = this.getHashFromHref(node.tocItem?.href);
      if (nodeHash === targetHash) {
        return [node];
      }
      if (node.children?.length) {
        const subPath = this.findNodePathByHash(node.children, targetHash);
        if (subPath) {
          return [node, ...subPath];
        }
      }
    }
    return null;
  }

  /**
   * Recursively searches for the path to the target node by comparing object references.
   *
   * @param nodes - The list of nodes to search.
   * @param target - The target node to find.
   * @returns An array representing the path to the target node, or null if not found.
   */
  private findNodePathByRef(nodes: TreeNode[], target: TreeNode): TreeNode[] | null {
    for (const node of nodes) {
      if (node === target) {
        return [node];
      }
      if (node.children?.length) {
        const subPath = this.findNodePathByRef(node.children, target);
        if (subPath) {
          return [node, ...subPath];
        }
      }
    }
    return null;
  }

  /**
   * Transforms the raw table of contents data into an array of TreeNode objects.
   *
   * @param tocItems - The raw TOC items from the book.
   * @returns An array of TreeNode objects formatted for the tree component.
   */
  private transformBookTocData(tocItems: TocItem[]): TreeNode[] {
    return tocItems.map(item => this.buildTreeNodeFromTocItem(item));
  }

  /**
   * Recursively builds a TreeNode from a given TOC item.
   *
   * @param tocItem - A single TOC item object from the book.
   * @returns A TreeNode representing the TOC item, including any nested children.
   */
  private buildTreeNodeFromTocItem(tocItem: TocItem): TreeNode {
    const children =
      tocItem.subItems && tocItem.subItems.length > 0
        ? tocItem.subItems.map((subItem: TocItem) => this.buildTreeNodeFromTocItem(subItem))
        : undefined;
    return {
      id: this.generateNodeId(),
      name: tocItem.label,
      tocItem,
      children,
    };
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * Completes all active subscriptions to prevent memory leaks.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
