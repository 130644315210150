import { Component, Input, ElementRef } from '@angular/core';
import { KeyboardKey } from 'src/app/enums/keyboard-keys.enum';

@Component({
  selector: 'reader-ui-info-component',
  templateUrl: './info-component.component.html',
  styleUrls: ['./info-component.component.scss'],
})
export class InfoComponentComponent {
  @Input() isLeftAligned = false;
  public isInfoVisible = false;

  constructor(private readonly elementRef: ElementRef) {}

  closeInfo(event: MouseEvent | KeyboardEvent): void {
    if (!this.isInfoVisible) return;
    this.preventAndStop(event);
    this.hideInfoPanel();
  }

  handleFocusOut(event: FocusEvent): void {
    const nextFocused = event.relatedTarget as HTMLElement | null;
    if (!nextFocused || !this.elementRef.nativeElement.contains(nextFocused)) {
      this.hideInfoPanel();
    }
  }

  handleInfoClick(event: KeyboardEvent | MouseEvent): void {
    this.preventAndStop(event);
    this.isInfoVisible ? this.hideInfoPanel() : this.showInfoPanel();
  }

  handleKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case KeyboardKey.Escape:
        if (this.isInfoVisible) {
          this.preventAndStop(event);
          this.hideInfoPanel();
        }
        break;
      case KeyboardKey.Tab:
        if (event.shiftKey && this.isInfoVisible) {
          this.hideInfoPanel();
        }
        break;
      case KeyboardKey.Enter:
      case KeyboardKey.Space:
        this.preventAndStop(event);
        this.handleInfoClick(event);
        break;
    }
  }

  private showInfoPanel(): void {
    this.isInfoVisible = true;
  }

  private hideInfoPanel(): void {
    if (!this.isInfoVisible) return;
    this.isInfoVisible = false;
  }

  private preventAndStop(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
