<div class="wrapper-info">
  <button
    #infoButton
    id="info-button"
    type="button"
    class="infobtn"
    aria-label="'contents.toc_aria_label' | translate"
    aria-controls="info-panel"
    [attr.aria-expanded]="isInfoVisible"
    (click)="handleInfoClick($event)"
    (keydown)="handleKeyDown($event)"
    (focusout)="handleFocusOut($event)"
  >
    <i class="dpg-icon dpg-icon-system-alert-info" aria-hidden="true"></i>
  </button>
  <div
    aria-level="1"
    [attr.aria-expanded]="isInfoVisible"
    aria-live="polite"
  >
    <ng-container *ngIf="isInfoVisible">
      <div
        #infoPanel
        id="info-panel"
        class="info"
        role="region"
        aria-atomic="true"
        aria-describedby="info-panel"
        [ngClass]="{ visible: isInfoVisible, 'left': isLeftAligned }"
        [attr.aria-hidden]="!isInfoVisible"
        (focusout)="handleFocusOut($event)"
        (keydown)="handleKeyDown($event)"
        [innerHTML]="'contents.toc_info' | translate"
      ></div>
    </ng-container>
  </div>
</div>