/* eslint-disable @typescript-eslint/quotes */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnnotationType, DropdownState } from '../../annotation.data';
import { CheckboxModule } from '@mhe/ngx-shared';

@Component({
  selector: 'rdrx-annotations-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    CheckboxModule,
  ],
  template: `
    <div class="dropdown" [ngSwitch]="type">
      <mhe-checkbox
        *ngIf="isEditMode"
        id="dropdown-highlights-checkbox"
        [checked]="areAllAnnotationsSelected"
        (change)="allAnnotationsSelected.emit($event)"
      ></mhe-checkbox>
      <ng-template [ngSwitchCase]="AnnotationType.HIGHLIGHT">
        <button
          mat-button
          [matMenuTriggerFor]="belowMenu"
        >
          <div class="dropdown-button">
            <span class="filter-title">{{ highlightsTitleTranslation }}</span>
            <span class="arrow-down-icon"></span>
          </div>
        </button>
        <mat-menu class="contents-dropdown" #belowMenu="matMenu" yPosition="below">
          <button
            mat-menu-item (click)="handleAllAnnotationsSelected()">
            {{ 'contents.dropdown_all_highlights' | translate }}
          </button>
          <button
            mat-menu-item (click)="handleDetachedAnnotationsSelected()">
            {{ 'contents.dropdown_detached_highlights' | translate }}
          </button>
        </mat-menu>
      </ng-template>

      <ng-template [ngSwitchCase]="AnnotationType.NOTE">
        <button
          mat-button
          [matMenuTriggerFor]="belowMenu"
        >
          <div class="dropdown-button">
            <span class="filter-title">{{ notesTitleTranslation }}</span>
            <span class="arrow-down-icon"></span>
          </div>
        </button>
        <mat-menu class="contents-dropdown" #belowMenu="matMenu" yPosition="below">
          <button
            mat-menu-item (click)="handleAllAnnotationsSelected()">
            {{ 'contents.dropdown_all_notes' | translate }}
          </button>
          <button
            mat-menu-item (click)="handleDetachedAnnotationsSelected()">
            {{ 'contents.dropdown_detached_notes' | translate }}
          </button>
        </mat-menu>
      </ng-template>

      <ng-template [ngSwitchCase]="AnnotationType.PLACEMARK">
        <button
          mat-button
          [matMenuTriggerFor]="belowMenu"
        >
          <div class="dropdown-button">
            <span class="filter-title">{{ placemarksTitleTranslation }}</span>
            <span class="arrow-down-icon"></span>
          </div>
        </button>
        <mat-menu class="contents-dropdown" #belowMenu="matMenu" yPosition="below">
          <button
            mat-menu-item (click)="handleAllAnnotationsSelected()">
            {{ 'contents.dropdown_all_placemarks' | translate }}
          </button>
          <button
            mat-menu-item (click)="handleDetachedAnnotationsSelected()">
            {{ 'contents.dropdown_detached_placemarks' | translate }}
          </button>
        </mat-menu>
      </ng-template>
    </div>

    <!-- dpg-icon-dropdown-arrow-right -->
  `,
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationLisDropdownComponent {
  translateService = inject(TranslateService);

  @Input({ required: true }) type!: AnnotationType;
  @Input({ required: true }) isEditMode: boolean = false;
  @Input({ required: true }) areAllAnnotationsSelected!: boolean;
  @Output() annotationsDropdownState = new EventEmitter<DropdownState>();
  @Output() allAnnotationsSelected = new EventEmitter();

  AnnotationType = AnnotationType;
  DropdownState = DropdownState;

  dropdownState: DropdownState = DropdownState.ALL;

  get highlightsTitleTranslation(): string {
    switch (this.dropdownState) {
      case DropdownState.ALL:
        return this.translateService.instant('contents.dropdown_all_highlights');
      case DropdownState.DETACHED:
        return this.translateService.instant('contents.dropdown_detached_highlights');
      default:
        return this.translateService.instant('contents.dropdown_all_highlights');
    }
  }

  get notesTitleTranslation(): string {
    switch (this.dropdownState) {
      case DropdownState.ALL:
        return this.translateService.instant('contents.dropdown_all_notes');
      case DropdownState.DETACHED:
        return this.translateService.instant('contents.dropdown_detached_notes');
      default:
        return this.translateService.instant('contents.dropdown_all_notes');
    }
  };

  get placemarksTitleTranslation(): string {
    switch (this.dropdownState) {
      case DropdownState.ALL:
        return this.translateService.instant('contents.dropdown_all_placemarks');
      case DropdownState.DETACHED:
        return this.translateService.instant('contents.dropdown_detached_placemarks');
      default:
        return this.translateService.instant('contents.dropdown_all_placemarks');
    }
  };

  handleAllAnnotationsSelected(): void {
    this.dropdownState = DropdownState.ALL;
    this.annotationsDropdownState.emit(DropdownState.ALL);
  }

  handleDetachedAnnotationsSelected(): void {
    this.dropdownState = DropdownState.DETACHED;
    this.annotationsDropdownState.emit(DropdownState.DETACHED);
  }
}
