export enum KeyboardKey {
  Tab = 'Tab',
  Escape = 'Escape',
  Enter = 'Enter',
  Space = ' ',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}
