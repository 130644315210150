import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import { Observable, combineLatest } from 'rxjs';
import { map, defaultIfEmpty } from 'rxjs/operators';

import {
  RuntimeConfiguration,
  ContentsMenuConfig,
  ReaderEnvironmentConfig,
  FeatureFlags,
} from '@mhe/reader/models';
import {
  ConfigurationState,
  initialConfigurationState,
} from '@mhe/reader/core/state/configuration/configuration.state';

@Injectable()
export class ReaderConfigStore extends ExtendedComponentStore<
ConfigurationState,
any
> {
  constructor() {
    super(initialConfigurationState);
  }

  readonly runtime$: Observable<RuntimeConfiguration> = this.select(
    ({ runtime }) => runtime,
  );

  readonly environment$: Observable<ReaderEnvironmentConfig> = this.select(
    ({ environment }) => environment,
  );

  /** selectors */
  // auth | user
  readonly contextId$: Observable<string> = this.select(
    ({ runtime }) => runtime.contextId,
  );

  readonly epubReleaseUUID$: Observable<string | undefined> = this.select(
    ({ runtime }) => runtime.epubReleaseUUID,
  );

  readonly epubVersion$: Observable<string | undefined> = this.select(
    ({ runtime }) => runtime.epubVersion,
  );

  readonly platform$: Observable<string> = this.select(
    ({ runtime }) => runtime.platform,
  );

  readonly roles$ = this.select(({ runtime }) => runtime.roles);
  readonly userId$: Observable<string> = this.select(
    ({ runtime }) => runtime.userId,
  );

  // - derived
  readonly isStudent$ = this.select(this.roles$, (roles: string) => {
    const studentRoles = ['Learner', 'Student'];
    const isStudent = studentRoles.includes(roles);
    return isStudent;
  });

  readonly isTeacher$ = this.select(this.roles$, (roles: string) => {
    const teacherRoles = ['Instructor', 'Administrator'];
    const isTeacher = teacherRoles.includes(roles);
    return isTeacher;
  });

  // contents
  readonly highlights$ = this.select(({ runtime }) => runtime.highlights);
  readonly notes$ = this.select(({ runtime }) => runtime.notes);
  readonly placemarks$ = this.select(({ runtime }) => runtime.placemarks);
  readonly readspeaker$ = this.select(({ runtime }) => runtime.readspeaker);

  readonly readspeakerGender$ = this.select(
    ({ runtime }) => runtime.readspeakerGender,
  );

  readonly readSpeakerVoicePick$ = this.select(
    ({ runtime }) => runtime.readspeakerVoicePick,
  );

  readonly toc$ = this.select(({ runtime }) => runtime.toc);
  readonly topicsEndpoint$ = this.select(
    ({ runtime }) => runtime.topicsEndpoint,
  );

  readonly idmToken$ = this.select(({ runtime }) => runtime.idmToken);

  readonly subjectTopicsEndpoint$ = this.select(
    ({ runtime }) => runtime.subjectTopicsEndpoint,
  );

  readonly dfaCategoryUUID$ = this.select(
    ({ runtime }) => runtime.dfaCategoryUUID,
  );

  readonly dfaTopicUUID$ = this.select(({ runtime }) => runtime.dfaTopicUUID);
  readonly dfaSearchLaunchParams$ = this.select(({ runtime }) => {
    return {
      spineID: runtime.dfaSearchLaunchSpineID,
      index: runtime.dfaSearchLaunchIndex,
      text: runtime.dfaSearchLaunchText,
    };
  });

  // ai-assist
  readonly isAiAssistEnabled$ = this.select(({ runtime }) => {
    return runtime.isAiAssistEnabled &&
      (runtime.sectionXid !== undefined && runtime.sectionXid !== '');
  });

  // Project's version of NgRx select method accepts only four args, hence chaining contentsMenuFlag4of5 and contentsMenuFlag5of5; can be consolidated once project's NgRx has been brought up to date
  readonly contentsMenuFlag4of5$ = this.select(
    this.highlights$,
    this.notes$,
    this.placemarks$,
    this.toc$,
    this.isAiAssistEnabled$,
    (highlights, notes, placemarks, toc, isAiAssistOffered) => ({
      highlights,
      notes,
      placemarks,
      toc,
      isAiAssistOffered,
    }),
  );

  readonly contentsMenuFlag5of5$ = this.select(
    this.contentsMenuFlag4of5$,
    this.topicsEndpoint$,
    (flags, topicsEndpoint) => ({
      ...flags,
      topicsEndpoint,
    }),
  );

  readonly sessionDisabled$ = this.select(
    ({ runtime }) => runtime.sessionDisabled,
  );

  // navigation
  readonly navigation$ = this.select(({ runtime }) => runtime.navigation);
  readonly navigationType$ = this.select(
    ({ runtime }) => runtime.navigationType,
  );

  readonly location$ = this.select(({ runtime }) => runtime.location);
  readonly cfi$ = this.select(({ runtime }) => runtime.cfi);
  readonly launchPresentationReturnUrl$ = this.select(
    ({ runtime }) => runtime.launchPresentationReturnUrl,
  );

  readonly pageStartCfi$ = this.select(({ runtime }) => runtime.pageStartCfi);
  readonly pageEndCfi$ = this.select(({ runtime }) => runtime.pageEndCfi);

  // assessments | assignments
  readonly assignment$ = this.select(({ runtime }) => runtime.assignment);
  readonly displayLevels$ = this.select(({ runtime }) => runtime.displayLevels);
  readonly levelDefault$ = this.select(({ runtime }) => runtime.levelDefault);
  readonly lastReopen$ = this.select(({ runtime }) => runtime.lastReopen);

  readonly assessmentLtiPost$ = this.select(
    ({ runtime }) => runtime.assessmentLtiPost,
  );

  readonly assessmentLtiPostLaunchParams$ = this.select(
    ({ runtime }) => runtime.assessmentLtiPostLaunchParams,
  );

  readonly deliveryScoresheetView$ = this.select(
    ({ runtime }) => runtime.deliveryScoresheetView,
  );

  // - derived flags
  readonly isAssessment$ = this.select(
    this.assessmentLtiPost$,
    this.assessmentLtiPostLaunchParams$,
    (post, launchParams) => !!post && !!launchParams,
  );

  readonly isScoresheetMode$ = this.select(
    this.assignment$.pipe(map((assn) => assn === 'review')),
    this.roles$.pipe(map((r) => r === 'Instructor')),
    this.deliveryScoresheetView$.pipe(map((ss) => ss === 'full')),
    (...configs) => configs.every((c) => c),
  );

  /** lexile levels */
  readonly lexileLevelCourse$ = this.select(
    ({ runtime }) => runtime.lexileLevelCourse,
  );

  readonly lexileLevelUser$ = this.select(
    ({ runtime }) => runtime.lexileLevelUser,
  );

  // miscl. features
  readonly authoring$ = this.select(({ runtime }) => runtime.authoring);
  readonly contentWidth$ = this.select(({ runtime }) => runtime.contentWidth);
  readonly links$ = this.select(({ runtime }) => runtime.links);
  readonly fontResizer$ = this.select(({ runtime }) => runtime.fontResizer);
  readonly backButton$ = this.select(({ runtime }) => runtime.backButton);
  readonly print$ = this.select(({ runtime }) => runtime.print);
  readonly interfaceMode$ = this.select(({ runtime }) => runtime.interfaceMode);
  readonly lastLocation$ = this.select(({ runtime }) => runtime.lastLocation);
  readonly launchPresentationCssUrl$ = this.select(
    ({ runtime }) => runtime.launchPresentationCssUrl,
  );

  readonly bannerMessage$ = this.select(({ runtime }) => runtime.bannerMessage);
  readonly fallbackToDefaultPublish$ = this.select(({ runtime }) =>
    runtime.fallbackToDefaultPublish);

  readonly navbar$ = this.select(({ runtime }) => runtime.navbar);
  readonly oauthConsumerKey$ = this.select(
    ({ runtime }) => runtime.oauthConsumerKey,
  );

  readonly overflow$ = this.select(({ runtime }) => runtime.overflow);
  readonly previewMode$ = this.select(({ runtime }) => runtime.previewMode);
  readonly readiator$ = this.select(({ runtime }) => runtime.readiator);
  readonly scrubber$ = this.select(({ runtime }) => runtime.scrubber);
  readonly search$ = this.select(({ runtime }) => runtime.search);
  readonly isSinglePageViewOnly$ = this.select(({ runtime }) => runtime.singlePageViewOnly);
  readonly tagging$ = this.select(({ runtime }) => runtime.tagging);
  readonly taggingElements$ = this.select(
    ({ runtime }) => runtime.taggingElements,
  );

  readonly titleOverride$ = this.select(({ runtime }) => runtime.titleOverride);
  readonly viewMode$ = this.select(({ runtime }) => runtime.viewMode);

  // dfa
  readonly elasticSearchEndPoint$ = this.select(
    ({ runtime }) => runtime.elasticSearchEndpoint,
  );

  readonly elasticSearchSuggestionsEndPoint$ = this.select(
    ({ runtime }) => runtime.elasticSearchSuggestionsEndPoint,
  );

  readonly elasticSearchMinChars$ = this.select(
    ({ runtime }) => runtime.elasticSearchMinChars,
  );

  readonly enableElasticSearch$ = this.elasticSearchEndPoint$.pipe(
    map((endpoint) => !!endpoint && endpoint.length > 0),
    defaultIfEmpty(false),
  );

  // - derived flags
  readonly ava$ = this.select(
    this.readiator$,
    this.authoring$,
    (readiator, authoring) => readiator && authoring,
  );

  readonly k5$ = this.select(this.interfaceMode$, (mode) => mode === 'k5');
  readonly dfa$ = this.select(this.interfaceMode$, (mode) => mode === 'dfa');
  readonly ribac$ = this.select(
    this.interfaceMode$,
    (mode) => mode === 'ribac',
  );

  /**
   * Options for the contents menu (left-slide out).
   *
   * In the case where a session ends, an error
   * will be displayed within each tab, so we
   * still want to show them.
   */
  readonly contentsMenuConfig$: Observable<ContentsMenuConfig> = combineLatest([
    this.contentsMenuFlag5of5$,
    this.readiator$,
    this.sessionDisabled$,
    this.k5$,
    this.dfa$,
  ]).pipe(
    map(
      ([
        { highlights, notes, placemarks, toc, topicsEndpoint },
        readiator,
        sessionDisabled,
        k5,
        dfa,
      ]): ContentsMenuConfig => {
        if ((readiator && !k5 && !dfa) || sessionDisabled) {
          return {
            placemarks: false,
            highlights: false,
            notes: false,
            toc,
            topicsEndpoint,
          };
        }
        return { highlights, notes, placemarks, toc, topicsEndpoint };
      },
    ),
  );

  /**
   * Whether or not to show the contents menu (left-slide out).
   */
  readonly hasContentsMenuTab$ = this.contentsMenuConfig$.pipe(
    map((contents) =>
      Object.keys(contents).some((key) => contents[key] === true),
    ),
  );

  /** updaters */
  readonly setConfig = this.updater(
    (
      state,
      {
        features,
        runtime,
        environment,
      }: {
        features: FeatureFlags
        runtime: RuntimeConfiguration
        environment: ReaderEnvironmentConfig
      },
    ) => ({
      ...state,
      features,
      runtime,
      environment,
    }),
  );
}
