import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { EpubViewerStore } from '../components/epub-viewer';

import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';

import { ReaderStore } from '@mhe/reader/components/reader/state';
import * as readerActions from '@mhe/reader/components/reader/state/reader.actions';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Injectable()
export class AiAssistMediator extends ComponentEffects {
  private readonly navigationActions$ = this.navigationStore.actions$;
  private readonly readerActions$ = this.readerStore.actions$;

  private readonly citationClassname = 'ai-reader-citation';
  private nodeUuids: string[] = [];

  constructor(
    private readonly ePubViewerStore: EpubViewerStore,
    private readonly navigationStore: NavigationStore,
    private readonly readerStore: ReaderStore,
    private readonly liveAnnouncer: LiveAnnouncer,
  ) {
    super();
  }

  /**
   * Important to unpaint citation nodes if the panel is closed
   */
  private readonly toggleAiAssist$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.toggleAiAssistPanel),
      withLatestFrom(this.readerStore.isAiAssistOpen$),
      map(([, open]) => {
        this.readerStore.setAiAssistOpen(!open);

        if (open) {
          this.readerStore.dispatch(readerActions.unpaintAiAssistCitation);
        }
      }),
      logCatchError('toggleAiAssist$'),
    ),
  );

  /**
   * Create these two side effects, nav and postRender, BEFORE the start effect
   */
  private readonly $afterNavAiAssistCitation = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.startAiAssistCitation),
      switchMap(() =>
        this.navigationActions$.pipe(
          ofType(navigationActions.afterNavigation),
          take(1),
          tap(() => {
            this.readerStore.dispatch(readerActions.paintAiAssistCitation);
          }),
        ),
      ),
    ),
  );

  private readonly $postRenderAiAssistCitation = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.startAiAssistCitation),
      switchMap(() =>
        this.readerActions$.pipe(
          ofType(readerActions.postRender),
          take(1),
          tap(() => {
            this.readerStore.dispatch(readerActions.paintAiAssistCitation);
          }),
        ),
      ),
    ),
  );

  /**
   * The start effect should be created AFTER the two side effects, nav and postRender
   *
   * This ensures the side effects are ready before the first dispatch of the start action
   */
  private readonly $startAiAssistCitation = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.startAiAssistCitation),
      map((action) => {
        this.nodeUuids = action.nodeUuids;

        // navigate in case the citation is on a different page
        this.navigationStore.dispatch(
          navigationActions.navigateByCfi({ cfi: action.cfi, setFocus: false }),
        );
      }),
    ));

  /**
   * Only paint citation nodes when called upon by an action
   *
   * Can happen these ways after a citation button is clicked:
   *   Same page, navigation action, but no render cycle
   *     Can paint immediately
   *   Different page, navigation action, and a new render cycle
   *     Can only paint after postRender hook
   */
  private readonly $paintCitation = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.paintAiAssistCitation),
      withLatestFrom(
        this.ePubViewerStore.cloIframe$,
      ),
      map(([, iframe]) => {
        if (iframe?.contentDocument) {
          const doc = iframe.contentDocument;
          this.unpaintNodes(doc);
          this.paintNodes(doc);
          this.announceCitations(doc);
        }
      }),
    ),
  );

  private readonly $unpaintCitation = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.unpaintAiAssistCitation),
      withLatestFrom(this.ePubViewerStore.cloIframe$),
      map(([, iframe]) => {
        if (iframe?.contentDocument) {
          this.unpaintNodes(iframe.contentDocument);
        }
      }),
    ),
  );

  /**
   * Helpers for mutating the ePub content document
   */
  private unpaintNodes(doc: Document): void {
    // un-paint any existing citations
    const existing: NodeListOf<Element> = doc.querySelectorAll('.' + this.citationClassname);
    existing.forEach((el: Element) => el.classList.remove(this.citationClassname));
  }

  private paintNodes(doc: Document): void {
    // do not apply painting to some types of elements
    const excludedTags = ['body', 'section', 'table'];

    let firstNode: HTMLElement | null = null;

    // loop nodes supposedly on the page
    for (const nodeUuid of this.nodeUuids) {
      const node = doc.getElementById(nodeUuid);
      if (!node || excludedTags.includes(node.tagName.toLowerCase())) {
        continue;
      }

      if (!firstNode) firstNode = node;

      // paint the node
      node.classList.add(this.citationClassname);

      // TODO instead of first node, query+loop and choose the element with lowest scroll point on the page

      // scroll the first node into view
      firstNode.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }

  private announceCitations(doc: Document): void {
    const announcedCitation: string[] = [];
    const querySelectedCitation = doc.querySelectorAll(`.${this.citationClassname}`);
    querySelectedCitation.forEach((el: HTMLElement) => {
      announcedCitation.push(el.textContent || '');
    });
    const joinAnnouncedCitation = announcedCitation.join(' ');
    void this.liveAnnouncer.announce(joinAnnouncedCitation, 'polite');
  }
}
